import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import { Select, TextInput, Button, Modal } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from '@mantine/notifications';
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import NewDocumentUploader from "../../components/NewDocumentUploader/NewDocumentUploader";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { createInvoiceDataAPI, updateInvoiceDataAPI } from "../../utilities/apis/recovery";

const AddInvoiceModal = ({
    formState,
    setFormState,
    dueDetailFormState,
    setDueDetailFormState,
    errors,
}) => {
    const multiUploadArgs = useMultiFileUpload({
        loadFromParent: true,
        parent: "invoiceData",
        parentId: formState.id,
    });
    formState.files = multiUploadArgs.finalFiles;

    const validateInvoiceData = () => {
        let errors = {};
        if (!formState.number) {
            errors.number = "Number is required";
        }
        if (!formState.date) {
            errors.date = "Date is required";
        }
        if (!formState.amount) {
            errors.amount = "Amount is required";
        }
        if (!formState.currencyType) {
            errors.currencyType = "Currency Type is required";
        }
        if (!formState.creditPeriod) {
            errors.creditPeriod = "Credit Period is required";
        }
        if (!formState.penaltyRate) {
            errors.penaltyRate = "Penalty Rate is required";
        }
        if (formState.files.length === 0) {
            errors.files = "Invoice copy is required";
            showNotification({
                color: 'red',
                title: 'Invoice Data Error',
                message: 'Invoice copy is required',
            });
        }
        setFormState((prevState) => ({
            ...prevState,
            errors,
        }));
        return Object.keys(errors).length === 0;
    };
    const createInvoiceData = async () => {
        let response = await apiWrapWithErrorWithData(
            createInvoiceDataAPI({
                number: formState.number,
                date: formState.date,
                amount: formState.amount,
                currencyType: formState.currencyType,
                creditPeriod: formState.creditPeriod,
                files: formState.files,
                penaltyRate: formState.penaltyRate,
                recoveryId: dueDetailFormState.recoveryId,
            })
        );
        if (response.success) {
            setFormState({
                ...formState,
                isModalOpen: false,
                isEdit: false,
                id: 0,
                number: "",
                date: "",
                amount: "",
                currencyType: "INR",
                creditPeriod: "",
                files: "",
                penaltyRate: "",
                errors: {},
            });
            setDueDetailFormState({
                ...dueDetailFormState,
                id: response.data.id,
                recoveryAmount: response.data.recoveryAmount,
                currencyType: response.data.currencyType,
                noOfInvoice: response.data.noOfInvoice,
                firstInvDate: new Date(response.data.firstInvDate),
                recoveryId: response.data.recoveryId,
            });
            multiUploadArgs.resetUploadedFiles();
        }
    }
    const updateInvoiceData = async () => {
        let response = await apiWrapWithErrorWithData(
            updateInvoiceDataAPI({
                id: formState.id,
                number: formState.number,
                date: formState.date,
                amount: formState.amount,
                currencyType: formState.currencyType,
                creditPeriod: formState.creditPeriod,
                files: formState.files,
                penaltyRate: formState.penaltyRate,
            })
        );
        if (response.success) {
            setFormState({
                ...formState,
                isModalOpen: false,
                isEdit: false,
                id: 0,
                number: "",
                date: "",
                amount: "",
                currencyType: "INR",
                creditPeriod: "",
                files: "",
                penaltyRate: "",
                errors: {},
            });
            setDueDetailFormState({
                ...dueDetailFormState,
                id: response.data.id,
                recoveryAmount: response.data.recoveryAmount,
                currencyType: response.data.currencyType,
                noOfInvoice: response.data.noOfInvoice,
                firstInvDate: new Date(response.data.firstInvDate),
                recoveryId: response.data.recoveryId,
            });
            multiUploadArgs.resetUploadedFiles();
        }
    }
    const handleFormSubmit = async () => {
        if (validateInvoiceData()) {
            if (formState.isEdit) {
                updateInvoiceData();
            } else {
                createInvoiceData();
            }
        }
    }

    return (
        <>
            <Modal
                overflow="inside"
                title={formState.isEdit ? "Update Invoice" : "Add New Invoice"}
                size="calc(80vw)"
                opened={formState.isModalOpen}
                onClose={() =>
                    setFormState({
                        ...formState,
                        isModalOpen: false,
                        isEdit: false,
                        id: 0,
                        number: "",
                        date: "",
                        amount: "",
                        currencyType: "INR",
                        creditPeriod: "",
                        files: "",
                        penaltyRate: "",
                        errors: {},
                    })
                }
                closeOnClickOutside={false}
            >
                <div className="grid grid-cols-3 gap-4">
                    <TextInput
                        required
                        label="Invoice Number"
                        type="text"
                        placeholder="Enter Invoice Number"
                        value={formState.number}
                        onChange={(event) => {
                            const newValue = event.currentTarget.value;
                            setFormState((prevState) => ({
                                ...prevState,
                                number: newValue,
                            }));
                        }}
                        error={errors.number}
                    />
                    <DatePicker
                        required
                        label="Invoice date"
                        placeholder="Select Invoice Date"
                        value={formState.date}
                        onChange={(date) => {
                            setFormState((prevState) => ({
                                ...prevState,
                                date,
                            }));
                        }}
                        error={errors.date}
                    />
                    <TextInput
                        required
                        label="Amount"
                        type="number"
                        placeholder="Enter Invoice Amount"
                        value={formState.amount}
                        onChange={(event) => {
                            const newValue = event.currentTarget.value;
                            setFormState((prevState) => ({
                                ...prevState,
                                amount: newValue,
                            }));
                        }}
                        error={errors.amount}
                    />
                    <Select
                        required
                        label="Currency Type"
                        data={[
                            { value: "INR", label: "INR" },
                            { value: "USD", label: "USD" },
                        ]}
                        placeholder="Select Currency Type"
                        value={formState.currencyType}
                        onChange={(value) => {
                            setFormState((prevState) => ({
                                ...prevState,
                                currencyType: value,
                            }));
                        }}
                        error={errors.currencyType}
                    />
                    <TextInput
                        required
                        label="Credit Period"
                        type="number"
                        placeholder="Enter Invoice Credit Period"
                        value={formState.creditPeriod}
                        onChange={(event) => {
                            const newValue = event.currentTarget.value;
                            setFormState((prevState) => ({
                                ...prevState,
                                creditPeriod: newValue,
                            }));
                        }}
                        error={errors.creditPeriod}
                    />
                    <TextInput
                        required
                        label="Penalty Rate per annum"
                        type="number"
                        placeholder="Enter Invoice Penalty Rate"
                        value={formState.penaltyRate}
                        onChange={(event) => {
                            const newValue = event.currentTarget.value;
                            setFormState((prevState) => ({
                                ...prevState,
                                penaltyRate: newValue,
                            }));
                        }}
                        error={errors.penaltyRate}
                    />
                </div>
                <div className="m-10">
                    <NewDocumentUploader multiUploadArgs={multiUploadArgs} />
                </div>
                <div className="flex justify-center mt-5">
                    <Button color="green" onClick={handleFormSubmit}>
                        {formState.isEdit ? "Update" : "Save"}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default AddInvoiceModal;
