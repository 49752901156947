import { ActionIcon, Button, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { Edit } from 'tabler-icons-react';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { createFinancialData, financialAnalyticsDetailsApi, getBudgetAmountYearly } from '../../utilities/apis/budgetManager';

import { getValueForInput, loadingStates } from '../../utilities/utilities';

function BudgetManagerTopDetails() {
  const [budget, setBudget] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    amount: '',
  });
  const [state, setState] = useState(true);
  const [val, setVal] = useState(0);

  const [configs, setConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    analytics: null,
  });

  const fetchAnalytics = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      loading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(financialAnalyticsDetailsApi());
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        analytics: resp.analytics,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: 'Financial Analytics',
      //   message: "Couldn't load financial analytics",
      //   color: 'red',
      // });
    }
  };
  useEffect(() => {
    fetchAnalytics();
  }, []);
  function getCurrentFinancialYear() {
    let financialYear = '';
    const today = new Date();
    if (today.getMonth() + 1 <= 3) {
      financialYear = `${today.getFullYear() - 1}-${today.getFullYear()}`;
    } else {
      financialYear = `${today.getFullYear()}-${today.getFullYear() + 1}`;
    }
    return financialYear;
  }
  const year = getCurrentFinancialYear();
  const obj = {};
  const result = configs.analytics?.budgetData.map((item) => {
    if (item.type === 'Recovery') {
      obj.recovery = item['_sum'].amount;
    } else {
      obj.expense = item['_sum'].amount;
    }
    return obj;
  });

  const changeHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setBudget((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const ans = async () => {
    const resp = await apiWrapWithErrorWithData(getBudgetAmountYearly());
    if (resp.success) {
      setState(true);
      setVal(resp.budget.amount);
    }
  };

  const serviceHandler = async () => {
    const budgetApiData = {
      amount: budget.amount,
    };
    const resp = await apiWrapWithErrorWithData(createFinancialData({ budgetData: budgetApiData }));
    if (resp?.success) {
      // setVal(resp.budgetFinancialy.amount);
      ans();
      setState(true);
      setBudget((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      showNotification({
        color: 'green',
        title: 'Budget Record.',
        message: 'Budget has been Added.',
      });
    } else {
      showNotification({
        color: 'red',
        title: 'Budget Record',
        message: 'Budget Record could not be created.',
      });
      setBudget((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
    }
  };

  useEffect(() => {
    ans();
  }, [val]);

  return (
    <div className="mt-5 mb-5 grid grid-cols-3 gap-x-4">
      <div
        className="mt-2 mb-2 border-2 border-none rounded-lg p-4"
        // style={{ backgroundColor: 'hsl(0, 100%, 90%)' }}
      >
        <div>
          Budget for financial year
          {' '}
          {year}
          <div className="mt-2">
            {!state && (
              <div className="flex flex-row">
                <div
                  className="pr-2"
                >
                  <TextInput
                    style={{ width: '150px' }}
                    placeholder="enter amount"
                    value={budget.amount}
                    onChange={changeHandler('amount')}
                  />
                </div>
                <Button
                  style={{ backgroundColor: '#46BDE1' }}
                  onClick={serviceHandler}
                >
                  Add
                </Button>
              </div>
            )}
            {state && (
            <div className="flex flex-row mt-2">
              <div
                className="font-bold text-lg"
              >
                {' '}
                INR
                {' '}
                {/* {budget.amount} */}
                {val}
                /-
              </div>
              <div className="pl-4">
                <ActionIcon>
                  <Edit
                    size={24}
                    onClick={() => {
                      setState(false);
                    }}
                  />
                </ActionIcon>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="mt-2 mb-2 border-2 border-none rounded-lg p-4"
        // style={{ backgroundColor: 'hsl(0, 100%, 90%)' }}
      >
        Total Expenditure as of Date
        {' '}
        <div
          className="font-bold mt-2 text-lg"
        >
          INR
          {' '}
          {obj.expense}
          /-
        </div>
      </div>
      <div
        // className="mt-2 mb-2"
        className="mt-2 mb-2 border-2 border-none rounded-lg p-4"
        // style={{ backgroundColor: 'hsl(0, 100%, 90%)' }}
      >
        Money Recoverd as of Date
        <div
          className="font-bold mt-2 text-lg"
        >
          INR
          {' '}
          {obj.recovery}
          /-
        </div>
      </div>
    </div>
  );
}

export default BudgetManagerTopDetails;
