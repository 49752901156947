/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
// import { Bar, Doughnut } from 'react-chartjs-2';
import { showNotification } from '@mantine/notifications';
import { Grid, Skeleton } from '@mantine/core';
import { loadingStates } from '../../utilities/utilities';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { casesAnalytics } from '../../utilities/apis/cases';
import { customChartData } from '../../utilities/utilities';
import DoughnutChart from '../doughnutChart/doughnutChart';

ChartJS.register(ArcElement, Tooltip, Legend);

const CaseAnalytics = () => {
  const [configs, setConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    data: null,
  });

  // const state = {
  //   labels: ['January', 'February', 'March',
  //     'April', 'May', 'June', 'July', 'August',
  //     'September', 'October', 'November', 'December'],
  //   datasets: [
  //     {
  //       label: 'cases',
  //       backgroundColor: 'rgba(75,192,192,1)',
  //       borderColor: 'rgba(0,0,0,1)',
  //       borderWidth: 0.5,
  //       barThickness: 30,
  //       barPercentage: 0.5,
  //       data: [65, 59, 80, 81, 56],
  //     },
  //   ],
  // };

  useEffect(() => {
    (async () => {
      setConfigs({ ...configs, loading: loadingStates.LOADING });
      const resp = await apiWrapWithErrorWithData(casesAnalytics());
      if (resp?.success && resp?.analytics) {
        setConfigs({
          ...configs,
          data: resp.analytics,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        });
      } else {
        // showNotification({
        //   color: 'red',
        //   title: 'Case Analytics',
        //   message: 'Failed to load case analytics.',
        // });
        setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
      }
    })();
  }, []);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxHeight: 10,
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <div className="w-full">
      <Grid>
        {configs.loading === loadingStates.LOADING
        && (
        <>
          <Grid.Col span={4}>
            <Skeleton style={{ height: '310px' }} className="mx-8" visible />
          </Grid.Col>
          <Grid.Col span={4}>
            <Skeleton style={{ height: '310px' }} className="mx-8" visible />
          </Grid.Col>
          <Grid.Col span={4}>
            <Skeleton style={{ height: '310px' }} className="mx-8" visible />
          </Grid.Col>
        </>
        )}
        {(configs.loading !== loadingStates.LOADING && configs.data?.casesStatusWise)
        && (
        <>
          <Grid.Col span={4}>
            <div
              // className="border-solid border-gray-100 border-2 rounded-lg p-4"
              // style={{ border: 'none', boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div>Cases by status</div>
              <div
                style={{
                  // height: '240px',
                  height: '200px',
                  width: '100%',
                  padding: '16px',
                  position: 'relative'
                }}
              >
                <DoughnutChart 
                  chartOptions={chartOptions} 
                  method={customChartData('status')} 
                  data={configs.data.casesStatusWise}
                />
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={4}>
            <div
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div>Cases tasks by status</div>
              <div
                style={{
                  // height: '240px',
                  height: '200px',
                  width: '100%',
                  padding: '16px',
                  position: 'relative'
                }}
              >
                <DoughnutChart 
                  chartOptions={chartOptions} 
                  method={customChartData('status')} 
                  data={configs.data.casesTaskStatusWise}
                />
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={4}>
            <div
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div>Next one month</div>
              <div
                style={{
                  // height: '240px',
                  height: '200px',
                  width: '100%',
                  padding: '16px',
                }}
              >
                <DoughnutChart 
                  chartOptions={chartOptions} 
                  method={customChartData('fixedFor')} 
                  data={configs.data.nextOneMonthCases}
                />
              </div>
            </div>
          </Grid.Col>
        </>
        )}
      </Grid>
      {/* <div style={{ width: '500px' }}>
        <Bar
          data={state}
          options={{
            title: {
              display: true,
              text: 'Average Rainfall per month',
              fontSize: 20,
            },
            legend: {
              display: true,
              position: 'right',
            },
          }}
        />
      </div> */}
    </div>
  );
};

export default CaseAnalytics;
