/** @format */

import React from "react";
import{ useState ,useEffect } from 'react';
import {
  Text,
  Textarea,
  Select,
  TextInput,  
  SegmentedControl,
} from "@mantine/core";

import { camelCaseToTitle } from "../../utilities/utilities";


const AgreementParty = ({
  agreementFormJson,
  partyName,
  dropDownNames,
  setDropDownNames,
  form,
}) => {
  const type = agreementFormJson[partyName].type || "Individual";

  const [phone, setPhone] = useState('');

  const handlePhoneChange = (e) => {
      const value = e.target.value;
      // Allow only up to 10 digits
      if (value.length <= 10 && /^\d*$/.test(value)) {
          setPhone(value);

          // Update the form state only for a complete 10-digit number
          if (value.length === 10) {
              form.setValue(`${partyName}.phone`, value);
          }
      }
  };

  // Debugging: Log the current phone state and form state value
  console.log("Local phone state:", phone);
  console.log("Form state for phone:", form.values[`${partyName}.phone`]);

  return (
    <div className="flex flex-col">
      <br></br>
      <div className="flex justify-between">
        <Text color="#46BDE1">{camelCaseToTitle(partyName)}</Text>
        <SegmentedControl
          {...form.getInputProps(`${partyName}.type`)}
          color="cyan"
          data={["Individual", "Entity"]}
        />
      </div>
      
      <div className="grid grid-cols-3 gap-4">
        <Select
          required
          searchable
          creatable
          className="inputCustom mx-4"
          label="Name"
          placeholder="Enter Name"
          data={[...dropDownNames]}
          getCreateLabel={(query) => `+ Add ${query}`}
          onCreate={(query) => {
            setDropDownNames([...dropDownNames, query]);
          }}
          {...form.getInputProps(`${partyName}.name`)}
          style={{ width: "305px", marginLeft: "0px" }}
        />
        <TextInput
          required
          label="Authorised Signatory Email ID"
          placeholder="Enter Authorised Signatory Email ID"
          {...form.getInputProps(`${partyName}.email`)}
          
        />
        
        {type === "Individual" ? (
          <TextInput
            label="Business POC"
            placeholder="Enter Business POC"
            {...form.getInputProps(`${partyName}.representative`)}
          />
        ) : (
          <Select
            className="inputCustom mx-4"
            label="Entity Type"
            data={[
              { value: "Company", label: "Company" },
              { value: "LLP", label: "LLP" },
              { value: "Partnership Firm", label: "Partnership Firm" },
              { value: "Sole Proprietary", label: "Sole Proprietary" },
            ]}
            {...form.getInputProps(`${partyName}.companyType`)}
          />
        )}
        {type === "Individual" ? (
          <TextInput
            label="Authorised Signatory"
            placeholder="Enter Signatory"
            {...form.getInputProps(`${partyName}.signatory`)}
          />
        ) : (
          <Textarea
            label="Corporate Address"
            placeholder="Enter Corporate Address"
            {...form.getInputProps(`${partyName}.corporateOfficeAddress`)}
          />
        )}
        
        <TextInput  
                label="Phone" 
                placeholder="Enter 10 digit phone no."
                value={phone}
                onChange={handlePhoneChange}
                {...form.getInputProps(`${partyName}.phone`)}

            />
        <Textarea
          label="Registered Address"
          placeholder="Enter Registered Address"
          {...form.getInputProps(`${partyName}.address`)}
        />

      </div>
    </div>
  );
};

export default AgreementParty;
