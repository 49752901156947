/** @format */

import React, { useEffect, useState } from "react";
import {
  Select,
  TextInput,
  Button,
  Modal,
  Text,
  Divider,
  SegmentedControl,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import flat from "flat";
import { DatePicker } from "@mantine/dates";
import { formatISO, parseISO } from "date-fns";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../../../utilities/apiHelpers";
import {
  getAgreementTitleApi,
  getAgreementTypesApi,
  getRegisterUserApi,
  bulkAgreementUploadApi,
} from "../../../utilities/apis/agreements";
import TemplatesList from "../../../components/TemplateManager/TemplatesList";
import { downloadCsvApi } from "../../../utilities/apis/templates";
import styles from "./AgreementBulkCreate.module.css";
import colors from "../../../utilities/design";
import AgreementBulkParty from "./AgreementBulkParty";
import AgreementBulkConfirm from "./AgreementBulkConfirm";
import AgreementBulkSteps from "./AgreementBulkSteps";
import { getPartiesList } from "../../../utilities/apis/parties";
import { handleChangeUpload } from "./utils";

function AgreementBulkCreate() {
  const DENAMINATION_OPTIONS = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "300", value: 300 },
    { label: "500", value: 500 },
    { label: "600", value: 600 },
    { label: "1000", value: 1000 },
    { label: "5000", value: 5000 },
    { label: "15000", value: 15000 },
    { label: "20000", value: 20000 },
    { label: "25000", value: 25000 },
    { label: "50000", value: 50000 },
  ];
  const [confirmSubmit, setConfirmSubmit] = useState({
    flag: false,
    data: [],
    formData: null,
  });
  const [denomination, setDenomination] = useState("");
  const [dropDownNames, setDropDownNames] = useState([]);
  const [parties, setParties] = useState([]);
  const initialState = {
    typeOfAgreementData: [],
    typeOfAgreement: null,
    titleOfAgreementData: [],
    titleOfAgreement: null,
    dateOfContract: null,
    noOfMonths: "0",
    noOfYears: "1",
    reminderTimeInDays: "30",
    count: 2,
  };
  const [partyData, setPartyData] = useState({
    type: "Individual",
    name: "",
    email: "",
    representative: "",
    companyType: "",
    signatory: "",
    corporateOfficeAddress: "",
    address: "",
  });

  const reminderLabels = {
    15: "15 days",
    30: "30 days",
    45: "45 days",
    60: "60 days",
  };

  const navigate = useNavigate();
  const agreementForm = useForm({ initialValues: flat(initialState) });
  const [configs, setConfigs] = useState({
    templateView: false,
    editorKey: "key-1",
    templateId: null,
    editorContent: agreementForm.contractQuillJsDelta || {
      ops: [{ insert: "Agreement content goes here..." }],
    },
    templateField: "",
    agreementType: "normal",
    signatureType: "eSignature",
  });
  const [requestingUser, setRequestingUser] = useState({
    requestingUserName: "",
    requestingUserEmail: "",
    requestingUserPhone: "",
    requestingUserDesignation: "",
    requestingUserBusinessDepartment: "",
  });
  const [uiConfigs, setUiConfigs] = useState({
    buttonLoading: false,
  });

  useEffect(() => {
    getAgreementTypes();
    getAgreementTitle();
    getRegisterUser();
    partiesApi();
  }, []);

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      agreementForm.setFieldValue("typeOfAgreementData", uniqueValues);
    }
  };

  const getAgreementTitle = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTitleApi());
    if (resp?.success && resp?.titlesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.titlesOfAgreement]));
      agreementForm.setFieldValue("titleOfAgreementData", uniqueValues);
    }
  };

  const getRegisterUser = async () => {
    const res = await apiWrapWithErrorWithData(getRegisterUserApi());
    setRequestingUser({
      requestingUserName: res.requestingUser.name,
      requestingUserEmail: res.requestingUser.email,
      requestingUserPhone: res.requestingUser.phone,
      requestingUserDesignation: res.requestingUser.designation,
      requestingUserBusinessDepartment: res.requestingUser.businessDepartment,
    });
  };

  const camelCaseToTitle = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const formValidation = () => {
    const keys = {};

    if (
      !agreementForm?.values?.typeOfAgreement ||
      agreementForm?.values?.typeOfAgreement?.length < 3
    ) {
      keys.typeOfAgreement = "Type is mandatory.";
    }

    if (
      !agreementForm?.values?.titleOfAgreement ||
      agreementForm?.values?.titleOfAgreement?.length < 3
    ) {
      keys.titleOfAgreement = "Title is mandatory.";
    }

    if (
      !agreementForm.values.dateOfContract ||
      isNaN(parseISO(agreementForm.values.dateOfContract).getTime())
    ) {
      keys["dateOfContract"] = "Please enter date.";
    }

    if (!(parseInt(agreementForm?.values?.count, 10) > 1)) {
      keys.count = "Bulk agreements count should be greater than 1";
    }

    if (
      (!agreementForm?.values?.noOfYears ||
        isNaN(agreementForm?.values?.noOfYears) ||
        parseInt(agreementForm?.values?.noOfYears, 10) <= 0) &&
      (!agreementForm?.values?.noOfMonths ||
        isNaN(agreementForm?.values?.noOfMonths) ||
        parseInt(agreementForm?.values?.noOfMonths, 10) <= 0)
    ) {
      keys["noOfYears"] = "Please check value.";
      keys["noOfMonths"] = "Please check value.";
    }

    if (Object.keys(keys).length !== 0) {
      agreementForm.setErrors(keys);
      showNotification({
        color: "red",
        title: "Bulk Agreement Form",
        message: "Please check all fields are filled properly.",
      });
    }
    return Object.keys(keys).length === 0;
  };

  const downloadCsvHandler = async () => {
    if (!formValidation()) return;

    if (!configs.templateId) {
      showNotification({
        title: "Bulk Agreement Form",
        message: "Please Select the agreement template",
        color: "red",
      });
      return;
    }

    const res = await apiWrapWithErrorWithData(
      downloadCsvApi({ templateId: configs.templateId })
    );
    if (res.success) {
      const { data } = res;
      const fields =
        data.fieldName && data.fieldName.length
          ? data.fieldName?.map((ele) => `ph_${ele}`)
          : [];
      let csvContent = `data:text/csv;charset=utf-8,`;

      let columns = [
        "templateId",
        "signatureType",
        "agreementType",
        "typeOfAgreement",
        "titleOfAgreement",
        "requestingUserName",
        "requestingUserEmail",
        "requestingUserPhone",
        "requestingUserDesignation",
        "requestingUserDepartment",
        "dateOfContract",
        "noOfYears",
        "noOfMonths",
        "reminderTimeInDays",
        "location",
        "firstPartyName",
        "firstPartyEmail",
        "firstPartyAddress",
        "firstPartySignatory",
        "firstPartyRepresentative",
        "secondPartyName",
        "secondPartyEmail",
        "secondPartyAddress",
        "secondPartySignatory",
        "secondPartyRepresentative",
      ];

      if (configs.agreementType === "eStamp") {
        columns.splice(2, 0, "denomination");
      }

      const columnsHead = columns.map((ele) => camelCaseToTitle(ele));
      csvContent += columnsHead.join(",") + ",";
      csvContent += fields.length ? fields?.join(",") + "\n" : "\n";
      const preFiledValues = {
        ...requestingUser,
        ...agreementForm.values,
        templateId: configs.templateId,
        denomination,
        agreementType: configs.agreementType,
        signatureType: configs.signatureType,
      };

      let preFiledContent = "";
      columns.forEach((ele) => {
        if (preFiledValues[ele]) {
          preFiledContent += `${preFiledValues[ele]},`;
        } else {
          preFiledContent += ",";
        }
      });

      for (let i = 0; i < agreementForm.values.count; i++) {
        csvContent += `${preFiledContent}\n`;
      }

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${data.name}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const uploadHandler = async (e) => {
    try {
      const [csvData, formData] = await handleChangeUpload(e);
      setConfirmSubmit((pre) => ({ ...pre, flag: true, data: csvData, formData }));
    } catch (error) {
      console.log({ error });
    }
  };

  const submitApiHandler = async () => {
    setUiConfigs({
      buttonLoading: true,
    });
    try {
      const { data } = await bulkAgreementUploadApi({ formData: confirmSubmit.formData });
      if (data.success) {
        showNotification({
          title: "Bulk Agreement Creation",
          message: `${data?.agreementsId.length} agreements created`,
          color: "green",
        });
        navigate("/app/agreements");
      } else {
        showNotification({
          title: "Bulk Agreement Creation",
          message: data.error || "Could not create agreements",
          color: "red",
        });
      }
      setUiConfigs({
        buttonLoading: false,
      });
    } catch (error) {
      setUiConfigs({
        buttonLoading: false,
      });
      showNotification({
        title: "Bulk Agreement Creation",
        message: error?.response?.data?.error || "Could not create agreements",
        color: "red",
      });
    }
  };

  const partiesApi = async (name) => {
    const { data: res } = await apiWrapWithErrorWithData(
      getPartiesList({ name })
    );
    setParties(res);
    const names = res.map(({ name: val }) => val);
    setDropDownNames(names);
  };

  const previousPageHandler = () =>
    setConfirmSubmit((pre) => ({ ...pre, flag: false }));

  return (
    <div>
      {confirmSubmit.flag ? (
        <AgreementBulkConfirm
          bulkAgreementData={confirmSubmit.data}
          onPrevious={previousPageHandler}
          onSubmit={submitApiHandler}
          loading={uiConfigs.buttonLoading}
        />
      ) : (
        <div className="w-full flex flex-col my-4">
          <AgreementBulkSteps />
          <Divider />
          <br></br>
          <div>
            <SegmentedControl
              mr={"10px"}
              color="blue"
              data={[
                { label: "Normal", value: "normal" },
                { label: "eStamp", value: "eStamp" },
              ]}
              value={configs.agreementType}
              onChange={(input) => {
                setConfigs((prevState) => ({
                  ...prevState,
                  agreementType: input,
                }));
              }}
            />
            <SegmentedControl
              color="blue"
              data={[
                { label: "eSignature", value: "eSignature" },
                { label: "Aadhaar esignature", value: "aadhaarEsignature" },
              ]}
              value={configs.signatureType}
              onChange={(input) => {
                setConfigs((prevState) => ({
                  ...prevState,
                  signatureType: input,
                }));
              }}
            />
            {configs.agreementType === "eStamp" ? (
              <div style={{ width: "41%" }} className="my-4">
                <Select
                  searchable
                  placeholder="Select denomination"
                  label="Denomination"
                  data={[...DENAMINATION_OPTIONS]}
                  value={denomination}
                  onChange={(selectedValue) => setDenomination(selectedValue)}
                />
              </div>
            ) : null}
          </div>
          <div className="grid grid-cols-2 my-4">
            <Select
              required
              searchable
              creatable
              className="max-w-lg"
              label="Type of agreement"
              placeholder="Select type of agreement"
              data={agreementForm.values.typeOfAgreementData}
              getCreateLabel={(query) => `+ Add ${query}`}
              onCreate={(query) => {
                agreementForm.setFieldValue(
                  "typeOfAgreementData",
                  Array.from(
                    new Set([
                      ...(agreementForm.values.typeOfAgreementData || []),
                      query,
                    ])
                  )
                );
              }}
              {...agreementForm.getInputProps("typeOfAgreement")}
            />
            <Select
              required
              searchable
              creatable
              className="max-w-lg"
              label="Title of agreement"
              placeholder="Select title of agreement"
              data={agreementForm.values.titleOfAgreementData}
              getCreateLabel={(query) => `+ Add ${query}`}
              onCreate={(query) => {
                agreementForm.setFieldValue(
                  "titleOfAgreementData",
                  Array.from(
                    new Set([
                      ...(agreementForm.values.titleOfAgreementData || []),
                      query,
                    ])
                  )
                );
              }}
              {...agreementForm.getInputProps("titleOfAgreement")}
            />
          </div>
          <div className="grid grid-cols-2 my-4">
            <DatePicker
              required
              className="max-w-lg"
              label="Date of contract"
              placeholder="Select date"
              {...{
                ...agreementForm.getInputProps("dateOfContract"),
                onChange: (val) => {
                  if (val && val.getTime) {
                    agreementForm
                      .getInputProps("dateOfContract")
                      .onChange(formatISO(val));
                  } else {
                    agreementForm
                      .getInputProps("dateOfContract")
                      .onChange(null);
                  }
                },
                value: agreementForm.values["dateOfContract"]
                  ? parseISO(agreementForm.values.dateOfContract)
                  : null,
              }}
            />
            <Select
              required
              placeholder="Reminder time"
              label="Renewal Reminder Time (in days)"
              className="max-w-lg"
              data={[
                { value: "15", label: reminderLabels["15"] },
                { value: "30", label: reminderLabels["30"] },
                { value: "45", label: reminderLabels["45"] },
                { value: "60", label: reminderLabels["60"] },
              ]}
              {...agreementForm.getInputProps("reminderTimeInDays")}
            />
          </div>
          <div
            className="flex my-4 justify-between"
            style={{ alignItems: "center" }}
          >
            <div>
              <Text>Contract term</Text>
              <div
                className="flex flex-row justify-between"
                style={{ width: "200px" }}
              >
                <TextInput
                  required
                  label="years"
                  placeholder="years"
                  {...agreementForm.getInputProps("noOfYears")}
                  style={{ width: "100px" }}
                />
                <TextInput
                  required
                  label="months"
                  placeholder="months"
                  {...agreementForm.getInputProps("noOfMonths")}
                  style={{ width: "80px" }}
                />
              </div>
            </div>
            <TextInput
              required
              label="Agreements Count"
              placeholder="count"
              {...agreementForm.getInputProps("count")}
              style={{ width: "200px", marginRight: "20px" }}
            />
            <Button
              onClick={() => {
                setConfigs((prevState) => ({
                  ...prevState,
                  templateView: true,
                }));
              }}
              style={{
                backgroundColor: "#46BDE1",
                marginRight: "100px",
              }}
            >
              Choose agreement template
            </Button>
          </div>
          {/* <div style={{ marginTop: '30px'}}>
          <AgreementBulkParty
            setDropDownNames={setDropDownNames}
            dropDownNames={dropDownNames}
            setPartyData={setPartyData}
            partyData={partyData}
          />
        </div> */}
          <div>
            {configs.templateView && (
              <Modal
                overflow="inside"
                opened
                onClose={() => {
                  setConfigs((prevState) => ({
                    ...prevState,
                    templateView: false,
                  }));
                }}
                size="calc(80vw)"
              >
                <TemplatesList
                  showNewTemplateBtn={false}
                  templateType="Agreements"
                  useTemplateFunc={(template) => {
                    console.log({
                      template,
                    });
                    setConfigs((prevState) => ({
                      ...prevState,
                      templateView: false,
                      editorContent: template.quillDelta,
                      templateId: template.id,
                      editorKey: "key-" + Math.random(),
                    }));
                  }}
                />
              </Modal>
            )}
            <div
              className="flex flex-row justify-center"
              style={{ marginTop: "30px" }}
            >
              <Button onClick={() => downloadCsvHandler()}>Download CSV</Button>
            </div>
          </div>
          <Divider className="my-4" />
          <div className={styles["upload-wrapper"]}>
            <div class={styles["file-input-wrapper"]}>
              <Text>Upload CSV file</Text>
              <Button
                className="my-2"
                color="gray"
              >
                <Text>Upload CSV</Text>
              </Button>
              {!uiConfigs.buttonLoading ? (
                <input
                  type="file"
                  name="file"
                  onChange={(e) => uploadHandler(e)}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AgreementBulkCreate;
