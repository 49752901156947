/** @format */

import React, { useState } from "react";
import { Select, Button } from "@mantine/core";

function TaskReviewForm({ onSubmit, loading }) {
  const [completionFlag, setCompletionFlag] = useState(false);

  return (
    <>
      <Select
        label="Task completion update"
        required
        placeholder="Select the status"
        data={[
          { value: true, label: "Completed" },
          { value: false, label: "Not Completed" },
        ]}
        onChange={setCompletionFlag}
      />
      <div className="flex justify-end my-8">
        <Button
          onClick={() => onSubmit(completionFlag)}
          disabled={loading}
          style={{
            backgroundColor: loading ? "#C8C8C8" : "#46BDE1",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
        >
          submit
        </Button>
      </div>
    </>
  );
}

export default TaskReviewForm;
