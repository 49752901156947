import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import { Edit, Trash, File } from 'tabler-icons-react';
import { Select, TextInput, Button, Table, ActionIcon } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import NewDocumentUploader from "../../components/NewDocumentUploader/NewDocumentUploader";
import AddInvoiceModal from "../../components/Recovery/AddInvoiceModal";
import InvoiceListSection from "../../components/Recovery/InvoiceListSection";

const DueDetailPage = ({ formState, setFormState, errors }) => {
  // const multiUploadArgs = useMultiFileUpload({ parent: 'recoveryRecord', parentId: id });
  const [invoiceData, setInvoiceData] = useState({
    isModalOpen: false,
    isEdit: false,
    id: 0,
    number: "",
    date: "",
    amount: "",
    currencyType: "INR",
    creditPeriod: "",
    files: "",
    penaltyRate: "",
    errors: {},
  });
  const multiUploadArgs = useMultiFileUpload({
    loadFromParent: true,
    parent: "dueDetail",
    parentId: formState.id,
  });
  formState.files = multiUploadArgs.finalFiles;

  return (
    <>
      <AddInvoiceModal
        formState={invoiceData}
        setFormState={setInvoiceData}
        dueDetailFormState={formState}
        setDueDetailFormState={setFormState}
        errors={invoiceData.errors}
      />
      <div className="grid grid-cols-4 gap-4 my-4 m-10 mt-10">
        <TextInput
          required
          disabled
          label="Recovery Amount"
          type="number"
          value={formState.recoveryAmount}
          onChange={(event) => {
            const newValue = event.currentTarget.value;
            setFormState((prevState) => ({
              ...prevState,
              recoveryAmount: newValue,
            }));
          }}
          error={errors.recoveryAmount}
        />
        <Select
          required
          disabled
          className="inputCustom w-full ml-0 leading-normal"
          label="Currency Type"
          data={[
            { value: "INR", label: "INR" },
            { value: "USD", label: "USD" },
          ]}
          value={formState.currencyType}
          onChange={(value) => {
            setFormState((prevState) => ({
              ...prevState,
              currencyType: value,
            }));
          }}
          error={errors.currencyType}
        />
        <DatePicker
          required
          disabled
          label="First Invoice"
          value={formState.firstInvDate}
          onChange={(value) => {
            setFormState((prevState) => ({
              ...prevState,
              firstInvDate: value,
            }));
          }}
          error={errors.firstInvDate}
        />
        <TextInput
          required
          disabled
          type="number"
          label="No. of Invoices"
          value={formState.noOfInvoice}
          onChange={(event) => {
            const newValue = event.currentTarget.value;
            setFormState((prevState) => ({
              ...prevState,
              noOfInvoice: newValue,
            }));
          }}
          error={errors.noOfInvoice}
        />
      </div>
      <InvoiceListSection dueDetailId={formState.id} invoiceData={invoiceData} setInvoiceData={setInvoiceData} setDueDetail={setFormState}/>
      <div className="flex justify-center mt-8">
        <Button
          color="green"
          onClick={() => {
            setInvoiceData((prevState) => ({
              ...prevState,
              isModalOpen: true,
            }));
          }}
        >
          Add Invoice
        </Button>
      </div>
      <div className="m-10">
        <NewDocumentUploader key={88} multiUploadArgs={multiUploadArgs} />
      </div>
    </>
  );
};

export default DueDetailPage;
