/** @format */

import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { showNotification } from "@mantine/notifications";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { SegmentedControl, Skeleton, Table, Anchor, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { loadingStates } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  expenseMonthlyApi,
  financialListApi,
} from "../../utilities/apis/budgetManager";
import BudgetManagerAnalytics from "./BudgetManagerAnalytics";
import colors from "../../utilities/design";
import BudgetManagerTopDetails from "./BudgetManagerTopDetails";

ChartJS.register(Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const BudgetManager = () => {
  const navigate = useNavigate();

  const [configs, setConfigs] = useState({
    listLoading: loadingStates.NO_ACTIVE_REQUEST,
    topSpLoading: loadingStates.NO_ACTIVE_REQUEST,
    list: null,
    module: "Case",
    expenseList: null,
  });
  const listOptions = [
    { label: "Cases", value: "Case" },
    { label: "Notices", value: "Notice" },
    { label: "Agreements", value: "Agreement" },
  ];

  const fetchList = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      listLoading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(
      financialListApi({
        module: configs.module.toLowerCase(),
      })
    );
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        listLoading: loadingStates.NO_ACTIVE_REQUEST,
        list: resp.list,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        listLoading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: "Financial Analytics",
      //   message: "Couldn't load list.",
      //   color: "red",
      // });
    }
  };

  const fetchMonthlyExpense = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      expenseLoading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(
      expenseMonthlyApi({ month: 0 })
    );
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
        expenseList: resp.listExpenseMonth,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: "Financial Analytics",
      //   message: "Couldn't load Monthly Expense",
      //   color: "red",
      // });
    }
  };

  useEffect(() => {
    fetchMonthlyExpense();
  }, []);

  useEffect(() => {
    fetchList();
  }, [configs.module]);

  return (
    <div className="flex flex-col">
      <BudgetManagerTopDetails />
      <BudgetManagerAnalytics />
      <div
        className="border-solid border-gray-100 border-2 rounded-lg p-4 mt-4"
        style={{
          border: "none",
          boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1)",
        }}
      >
        <div className="mt-2">On Month Expenditure(monthly wise)</div>
        {configs.expenseLoading === loadingStates.LOADING && (
          <Skeleton style={{ height: "200px" }} className="mt-4 mx-8" visible />
        )}
        {configs.expenseLoading === loadingStates.NO_ACTIVE_REQUEST &&
          configs.expenseList && (
            <div
              className="mt-4 w-full"
              style={{
                height: "200px",
                width: "100%",
              }}
            >
              <Bar
                data={{
                  labels: configs.expenseList.map(
                    (el) => `${el.month} ${el.year}`
                  ),
                  datasets: [
                    {
                      label: "Expense",
                      barThickness: 30,
                      barPercentage: 0.5,
                      data: configs.expenseList.map((el) => el.type["Expense"]),
                      backgroundColor: colors.expense,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "MONTHLY ACTIVITIES",
                    },
                  },
                }}
              />
            </div>
          )}
      </div>
      <div className="mb-40">
        <div className="flex items-center justify-between mt-4 px-4">
          <Text>Module Finances</Text>
          <SegmentedControl
            disabled={configs.listLoading === loadingStates.LOADING}
            color="blue"
            // color="cyan"
            value={configs.module}
            onChange={(val) => {
              setConfigs((prevState) => ({
                ...prevState,
                module: val,
              }));
            }}
            data={listOptions}
          />
        </div>
        {configs.listLoading === loadingStates.LOADING && (
          <div className="flex flex-col mt-4">
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
          </div>
        )}
        {configs.listLoading !== loadingStates.LOADING && configs.list && (
          <Table className="mt-4" striped>
            <thead>
              <tr>
                <th>Reference No.</th>
                <th>Expense</th>
                <th>Recovery</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {configs.list.map((row) => (
                <tr key={row.id}>
                  <td>{`${configs.module} - ${row.id}`}</td>
                  <td>{row.Expense ? `Rs. ${row.Expense}` : "Rs. 0"}</td>
                  <td>{row.Recovery ? `Rs. ${row.Recovery}` : "Rs. 0"}</td>
                  <td>
                    <Anchor
                      onClick={(e) => {
                        e.stopPropagation();
                        if (configs.module.toLowerCase() === "case") {
                          navigate(
                            `/app/dispute-manager/cases/details/${row.id}`
                          );
                        }
                        if (configs.module.toLowerCase() === "notice") {
                          navigate(
                            `/app/dispute-manager/legal-notices/details/${row.id}`,
                            {
                              state: {
                                noticeId: row.id,
                              },
                            }
                          );
                        }
                        if (configs.module.toLowerCase() === "agreement") {
                          navigate(`/app/agreements/details/${row.id}`);
                        }
                      }}
                    >
                      View
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default BudgetManager;
