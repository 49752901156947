import React from 'react';
import NewDocumentUploader from '../../components/NewDocumentUploader/NewDocumentUploader';
import useMultiFileUpload from '../../hooks/useMultiFileUpload';

const DocsComponent = ({ dueDetailId }) => {

    const multiUploadArgs = useMultiFileUpload({
        loadFromParent: true,
        parent: 'dueDetail',
        parentId: dueDetailId,
    });

    return (
        <div className='m-10'>
            <NewDocumentUploader hideDeleteButton hideDropZone multiUploadArgs={multiUploadArgs} />
        </div>
    );
};

export default DocsComponent;