import React, { useState, useEffect } from "react";
import { formatDate } from "../../utilities/utilities";
import { Table, ActionIcon, Modal, Button, Switch } from "@mantine/core";
import { Edit } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
    getEmailTemplateList,
    saveEmailTemplate,
    lockEmailTemplate,
    toggleActionAddInvoice
} from "../../utilities/apis/recovery";
import Editor from "../Editor";

const EmailComponent = ({ recoveryId }) => {
    const [templateData, setTemplateData] = useState({
        emailList: [],
        isModalOpen: false,
        content: {},
        templateId: null,
        actionId: null,
        html: null,
        executionDate: null,
        isEmailLocked: false,
        isInvoiceAdded: false,
    });
    const fetchEmailTemplates = async () => {
        const response = await apiWrapWithErrorWithData(
            getEmailTemplateList({ recoveryId })
        );
        if (response.success) {
            setTemplateData((prevState) => ({
                ...prevState,
                emailList: response.emailList,
            }));
        }
    };
    const handleEmailTemplateSave = async () => {
        const response = await apiWrapWithErrorWithData(
            saveEmailTemplate({
                actionId: templateData.actionId,
                emailTemplateContent: templateData.content,
            })
        );
        if (response.success) {
            fetchEmailTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                html: null,
                executionDate: null,
                isEmailLocked: false,
            }));
        }
    };
    const handleEmailTemplateLock = async () => {
        const response = await apiWrapWithErrorWithData(
            lockEmailTemplate({
                actionId: templateData.actionId,
                emailTemplateContent: templateData.content,
                emailTemplateHTML: templateData.html,
                isEmailLocked: true,
            })
        );
        if (response.success) {
            fetchEmailTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                html: null,
                executionDate: null,
                isEmailLocked: false,
            }));
        }
    };
    const handleEmailTemplateUnlock = async () => {
        const response = await apiWrapWithErrorWithData(
            lockEmailTemplate({
                actionId: templateData.actionId,
                emailTemplateContent: templateData.content,
                emailTemplateHTML: templateData.html,
                isEmailLocked: false,
            })
        );
        if (response.success) {
            fetchEmailTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                html: null,
                executionDate: null,
                isEmailLocked: false,
            }));
        }
    };
    const dateDiffCal = (data) => {
        const executionDate = new Date(data);
        const currentDate = new Date();
        const differenceInTime = currentDate - executionDate;
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays !== 1 ? true : false;
    };
    const handleAddingInvoiceData = async () => {
        templateData.isInvoiceAdded = !templateData.isInvoiceAdded;
        const payload = {
            recoveryId,
            actionId: templateData.actionId,
            isInvoiceAdded: templateData.isInvoiceAdded,
        }
        const response = await apiWrapWithErrorWithData(
            toggleActionAddInvoice(payload)
        );
        if (response.success) {
            fetchEmailTemplates();
        };
    }
    useEffect(() => {
        fetchEmailTemplates();
    }, [recoveryId]);

    return (
        <>
            <Modal
                title="Edit Email Template"
                overflow="inside"
                opened={templateData.isModalOpen}
                onClose={() => {
                    setTemplateData((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                        content: {},
                        templateId: null,
                        actionId: null,
                        html: null,
                        executionDate: null,
                        isEmailLocked: false,
                    }));
                }}
                size="calc(80vw)"
            >
                <div className="flex justify-between p-2 mb-5">
                    {!templateData.isEmailLocked ? (
                        <>
                            <Switch
                                checked={templateData.isInvoiceAdded}
                                onChange={handleAddingInvoiceData}
                                label={templateData.isInvoiceAdded ? "Hide Invoice Data" : "Show Invoice Data"}
                            />
                            <div>
                                <Button onClick={handleEmailTemplateSave}>Save</Button>
                                <Button className="mx-2" onClick={handleEmailTemplateLock}>
                                    Lock
                                </Button>
                            </div>
                        </>
                    ) : dateDiffCal(templateData.executionDate) ? (
                        <Button onClick={handleEmailTemplateUnlock}>Unlock</Button>
                    ) : (
                        <div className="font-bold text-[15px] leading-[1.55] text-red-500 text-center">
                            Time limit exceeded to unlock the email template.
                        </div>
                    )}
                </div>
                <Editor
                    locked={templateData.isEmailLocked}
                    content={templateData.content}
                    templateId={templateData.templateId}
                    onContentChange={(content) => {
                        setTemplateData((prevState) => ({
                            ...prevState,
                            content,
                        }));
                    }}
                    onHtmlChange={(content) => {
                        setTemplateData((prevState) => ({
                            ...prevState,
                            html: content,
                        }));
                    }}
                />
            </Modal>

            <div className="m-10">
                {templateData.emailList.length > 0 && (
                    <Table striped highlightOnHover={true}>
                        <thead>
                            <tr>
                                <td>Reference no.</td>
                                <td>Execution Date</td>
                                <td>Name</td>
                                <td>&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {templateData.emailList.map((row) => (
                                <tr key={row.id}> 
                                    <td>{`action-${row.id}`}</td>
                                    <td>{formatDate(row.executionDate)}</td>
                                    <td>{row.templateName}</td>
                                    <td>
                                        <div className="grid grid-cols-1">
                                            <ActionIcon
                                                title="Edit"
                                                icon="edit"
                                                onClick={() => {
                                                    dateDiffCal(row.executionDate);
                                                    setTemplateData((prevState) => ({
                                                        ...prevState,
                                                        isModalOpen: true,
                                                        content: row.emailTemplateContent,
                                                        templateId: row.emailTemplateId,
                                                        actionId: row.id,
                                                        html: row.emailTemplateHTML,
                                                        executionDate: row.executionDate,
                                                        isEmailLocked: row.isEmailLocked,
                                                    }));
                                                }}
                                            >
                                                <Edit color={!row.isEmailLocked ? "red" : "green"} />
                                            </ActionIcon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default EmailComponent;
