import React, { useState } from 'react';
// import { Badge, Button } from '@mantine/core';
import { Button, Modal } from '@mantine/core';
// import { Badge } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { showNotification } from '@mantine/notifications';
import CaseInfo from './CaseInfo';
import CompanyRepresentative from './CompanyRepresentative';
import CourtDetails from './CourtDetails';
import AddressRenderer from './AddressRenderer';
import styles from './CaseDetails.module.css';
import { formatDate, formatTime } from '../../utilities/utilities';
import CaseStatusDetails from '../../pages/CaseManagement/CaseStatusDetails';
import { deleteCases } from '../../utilities/apis/cases';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { selectUserData } from '../../redux/selectors';
import colors from '../../utilities/design';
// import { caseStatusColors } from '../../utilities/enums';
// import CaseStatusDetails from '../../pages/CaseManagement/CaseStatusDetails';
// import { caseStatusColors } from '../../utilities/enums';

function CaseDetails({
  caseData, caseId, upperHalf, lowerHalf, nextDate,
  // onEditClick,
}) {
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const userDetails = useSelector(selectUserData);

  const deleteHandler = async () => {
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(deleteCases({ caseId }));
    if (response?.success) {
      showNotification({
        color: 'green',
        title: 'Cases',
        message: 'Case deleted successfully.',
      });
      setOpen(false);
      navigate('/app/dispute-manager/cases');
    } else {
      showNotification({
        color: 'red',
        title: 'Cases',
        message: 'Unable to delete Case',
      });
    }
    setDeleteLoading(false);
  };

  return (
    <>
      {upperHalf && (
        <div className="flex flex-col pl-4 mb-16">
          <div className="flex flex-row justify-end my-3">
            <div className="flex flex-row justify-between">
              <Button
                className="ml-4"
                style={{
                  backgroundColor: '#46BDE1',
                  borderRadius: '0.5rem',
                  color: '#F5F5F5',
                // width: '180px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/app/dispute-manager/edit-case', {
                    state: {
                      caseId,
                    },
                  });
                }}
              >
                Edit
              </Button>
              {userDetails?.designation === 'Admin' ? (
                <Button
                  className="ml-4"
                  style={{
                    backgroundColor: '#ff4d4d',
                    borderRadius: '0.5rem',
                    color: '#F5F5F5',
                    // width: '180px',
                  }}
                  onClick={() => setOpen(true)}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          </div>
          <div className="flex justify-between items-center">
            {/* <div className="flex flex-row"> */}
            <div className="flex flex-col">
              <div className={styles.caseNumber}>
                {caseData.caseTitle}
                {' '}
              </div>
              <div className="flex flex-row mt-4">
                <div className="flex flex-row mr-4">
                  <img className="pr-2" src="/assets/images/calendar.svg" alt="calendar" />
                  <span className={styles.dateTime}>
                    Created on
                    {' '}
                    {formatDate(caseData.createdAt)}
                  </span>
                </div>
                <div className="flex flex-row mr-4">
                  <img className="pr-2" src="/assets/images/clock.svg" alt="clock" />
                  <span className={styles.dateTime}>
                    {' '}
                    {formatTime(caseData.createdAt)}
                  </span>
                </div>
                <div className="flex flex-row mr-4">
                  <img className="pr-2" src="/assets/images/eye.svg" alt="eye" />
                  <span className={styles.dateTime}>
                    Created by
                    {' '}
                    {caseData.createdBy.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-col">
              <CaseStatusDetails caseId={caseId} />
              {/* <Badge size="lg" color={caseStatusColors[caseData.status] || 'orange'}>
                {caseData.status}
              </Badge> */}
              {/* <Button
                onClick={onEditClick}
                className="ml-4"
                style={{
                  backgroundColor: '#46BDE1',
                  borderRadius: '0.5rem',
                  color: '#F5F5F5',
                  width: '180px',
                }}
              >
                Edit
            </Button> */}
            </div>
          </div>
          <CaseInfo {...caseData.caseNumber} />
          {/* <CaseInfo {...caseData.caseNumber} amount={caseData.amount} /> */}
          <CourtDetails nextDateMain={nextDate} {...caseData.courtDetails} />
          {/* <CompanyRepresentative {...caseData.companyRepresentative} /> */}
          <div className="mt-7">
            <div className={styles.title}>Complainants</div>
            {caseData.complainant.map((obj) => <AddressRenderer {...obj} />)}
          </div>
          <div className="mt-7">
            <div className={styles.title}>Respondents</div>
            {caseData.respondent.map((obj) => <AddressRenderer {...obj} />)}
          </div>
          <Modal
            overflow="inside"
            closeOnClickOutside={false}
            size="calc(30vw)"
            opened={open}
            onClose={() => setOpen(!open)}
            title="Delete Case"
          >
            <div className="my-2">
              <h4>Are you sure you want to delete this case?</h4>
              <div className="flex flex-row justify-end">
                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderRadius: '0.5rem',
                    color: '#F5F5F5',
                  // width: '180px',
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={deleteLoading}
                  className="ml-4"
                  style={{
                    backgroundColor: deleteLoading ? '#ffcccc' : '#ff4d4d',
                    borderRadius: '0.5rem',
                    color: '#F5F5F5',
                  // width: '180px',
                  }}
                  onClick={() => deleteHandler()}
                >
                  {deleteLoading ? <BeatLoader size={10} color={colors.rejected} /> : 'Delete'}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      { lowerHalf && (
      <div className="flex flex-col pt-7 pl-4 mb-20">
        {/* <CourtDetails nextDateMain={nextDate} {...caseData.courtDetails} /> */}
        {/* <div className="flex flex-row justify-end">
          <Button
            // onClick={onEditClick}
            // className="ml-4"
            // style={{
            //   backgroundColor: '#46BDE1',
            //   borderRadius: '0.5rem',
            //   color: '#F5F5F5',
            //   width: '180px',
            // }}
            onClick={(e) => {
              e.stopPropagation();
              navigate('/app/dispute-manager/edit-case', {
                state: {
                  caseId,
                },
              });
            }}
            // className="text-blue-600 cursor-pointer"
          >
            Edit
          </Button>
        </div> */}
        <CompanyRepresentative {...caseData.companyRepresentative} />
        {/* <div className="mt-7">
          <div className={styles.title}>Complainants</div>
          {caseData.complainant.map((obj) => <AddressRenderer {...obj} />)}
        </div> */}
        {/* <div className="mt-7">
          <div className={styles.title}>Respondents</div>
          {caseData.respondent.map((obj) => <AddressRenderer {...obj} />)}
        </div> */}
        <div className="mt-7">
          <div className={styles.title}>Complainant Lawyer</div>
          {caseData['complainant lawyer'].map((obj) => <AddressRenderer {...obj} />)}
        </div>
        <div className="mt-7">
          <div className={styles.title}>Respondents Lawyer</div>
          {/* {caseData['respondent lawyer'].map((obj) => <AddressRenderer {...obj} />)} */}
          {caseData['complainant lawyer'].map((obj) => <AddressRenderer {...obj} />)}
        </div>
      </div>
      )}
    </>
  );
}

export default CaseDetails;
