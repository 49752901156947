import {
  ActionIcon, Badge, Button, Select,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { differenceInDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Edit } from 'tabler-icons-react';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { getCaseDetails, updateCase } from '../../utilities/apis/cases';
import { caseStatusColors, caseStatusValues } from '../../utilities/enums';
import { getValueForInput, loadingStates } from '../../utilities/utilities';

function CaseStatusDetails({ caseId }) {
  // console.log(caseId);
  const [state, setState] = useState(false);
  const [days, setDays] = useState('');
  const [additionalSettings, setAdditionalSettings] = useState({
    status: '',
    // status: 'filing',
    loading: loadingStates.NO_ACTIVE_REQUEST,
    // pendingDays: '',
  });
  // console.log(additionalSettings.status);
  const onValueChange = (name) => (incoming) => {
    const value = getValueForInput(incoming);
    setAdditionalSettings((settings) => ({
      ...settings,
      [name]: value,
    }));
  };

  const getCaseData = async () => {
    const resp = await apiWrapWithErrorWithData(getCaseDetails({ caseId }));
    // console.log('result = ', resp?.case);
    // console.log('result = ', resp?.case.completionDate);
    if (resp.success) {
      setState(true);
      setDays(differenceInDays(new Date(resp?.case.completionDate),
        new Date(resp?.case.createdAt)));
      setAdditionalSettings({
        ...additionalSettings,
        status: resp?.case?.status,
      });
    }
  };
  useEffect(() => {
    setState(true);
    getCaseData();
  }, []);

  const saveCase = async () => {
    setAdditionalSettings((settings) => ({
      ...settings,
      loading: loadingStates.LOADING,
    }));
    const response = await apiWrapWithErrorWithData(updateCase({
      caseId,
      status: additionalSettings.status,
      days,
    }));
    if (response?.success) {
      setState(true);
      showNotification({
        title: 'Case',
        message: 'Case data saved.',
        color: 'green',
      });
    } else {
      showNotification({
        title: 'Case',
        message: 'Case data couldn\'t be saved.',
        color: 'red',
      });
    }

    setAdditionalSettings({
      ...additionalSettings,
      loading: loadingStates.LOADING,
    });
  };
  //   const saveCase = () => {
  //     setState(true);
  //   };

  return (
    <div className="flex flex-col">
      {!state && (
        <div className="flex flex-row">
          <Select
            style={{ width: '150px' }}
            placeholder="Pick one"
            data={caseStatusValues}
            value={additionalSettings.status}
            onChange={onValueChange('status')}
          />
          <Button
            onClick={saveCase}
            style={{ width: '75px' }}
            className="w-24"
          >
            Save
          </Button>
        </div>
      )}
      {/* <Badge size="lg" color={caseStatusColors[caseData.status] || 'orange'}>
                {caseData.status}
              </Badge> */}
      {state && (
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          {/* <Badge>{additionalSettings.status}</Badge> */}
          <Badge size="lg" color={caseStatusColors[additionalSettings.status] || 'orange'}>
            {additionalSettings.status}
          </Badge>
          <ActionIcon>
            <Edit
              size={24}
              onClick={() => {
                setState(false);
              }}
            />
          </ActionIcon>
        </div>
        {/* <div className="mt-2">
          pending since
          {' '}
          {days}
          {' '}
          days
        </div> */}
        {additionalSettings.status !== 'COMPLETED' && (
          <div className="mt-2">
            pending since
            {' '}
            {days}
            {' '}
            days
          </div>
        )}
      </div>
      )}
    </div>
  );
}

export default CaseStatusDetails;
