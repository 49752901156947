/** @format */

import React, { useEffect, useState } from "react";
import {
  // Textarea, TextInput, Select, Button,
  Textarea,
  TextInput,
  Button,
  Select,
  Text,
} from "@mantine/core";
// import { DatePicker, TimeInput } from '@mantine/dates';
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import { BeatLoader } from "react-spinners";
// import { useSelector } from 'react-redux';
import { formatISO, setHours, setMinutes, subSeconds } from "date-fns";
import styles from "./TaskNewForm.module.css";
import {
  getValueForInput,
  loadingStates,
  validateEmail,
} from "../../utilities/utilities";
// import UserSelector from '../UserSelector/UserSelector';
import NewDocumentUploader from "../NewDocumentUploader/NewDocumentUploader";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import colors from "../../utilities/design";
// import { selectAllWorkspaceUsers } from '../../redux/selectors';
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  createTaskApi,
  getTaskAssignedEmails,
} from "../../utilities/apis/tasks";

function TaskNewForm({ parent, parentId, onModalExit, title }) {
  const multiUploadArgs = useMultiFileUpload({});

  const [taskData, setTaskData] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    assignedToId: null,
    dueDate: null,
    reminderTime: " ",
    title: "",
    assignEmailTo: " ",
    reminderHours: "00",
    reminderMinutes: "00",
    pickHours: "00",
    pickMinutes: "00",
    pickInterval: "am",
    description: "",
    errors: {},
    emails: [],
  });

  const [totalEmail, setTotalEmail] = useState([]);
  // console.log('totalEmail = ', totalEmail);
  // console.log('assignEmailTo = ', taskData.assignEmailTo);
  // console.log('toMultiSelectData = ', taskData.toMultiSelectData);
  // console.log('toEmails = ', taskData.emails);
  // console.log('taskTitle For taskForm = ', taskData.parentTitle);

  let hourValue;
  if (taskData.pickInterval === "pm" && taskData.pickHours <= 12) {
    hourValue = parseInt(`${taskData.pickHours}`, 10) + 12;
  }
  if (taskData.pickInterval === "am" && taskData.pickHours <= 12) {
    hourValue = parseInt(`${taskData.pickHours}`, 10);
  }
  const dueDate = new Date(taskData.dueDate);
  const hours = parseInt(`${hourValue}`, 10);
  const minutes = parseInt(`${taskData.pickMinutes}`, 10);
  const result = setHours(dueDate, hours);
  const finalDueDate = setMinutes(result, minutes);
  if (taskData.reminderHours) {
    const remHours = parseInt(`${taskData.reminderHours}`, 10) * 60 * 60;
    taskData.reminderTime = remHours;
  }
  if (taskData.reminderMinutes) {
    const remMinutes = parseInt(`${taskData.reminderMinutes}`, 10) * 60;
    taskData.reminderTime = remMinutes;
  }

  if (taskData.reminderHours && taskData.reminderMinutes) {
    const remHours = parseInt(`${taskData.reminderHours}`, 10) * 60 * 60;
    const remMinutes = parseInt(`${taskData.reminderMinutes}`, 10) * 60;
    const totalSec = remHours + remMinutes;
    taskData.reminderTime = totalSec;
  }

  const changeHandler = (name) => (incoming) => {
    const value = getValueForInput(incoming);
    setTaskData({
      ...taskData,
      [name]: value,
    });
  };

  const emailCreateLabelHandler = (query) => (
    <Text color={validateEmail(query) ? "green" : "red"}>
      +Add
      {` ${query}`}
    </Text>
  );

  // const multiSelCreate = (stateName) => (query) => {
  //   if (!validateEmail(query)) {
  //     return;
  //   }
  //   const item = ({
  //     value: query,
  //     label: query,
  //   });
  //   console.log('itemValue = ', item.value);
  //   setTaskData((stateC) => ({
  //     ...stateC,
  //     [stateName]: [...stateC[stateName], item],
  //     assignedEmail: item.value,
  //   }));
  //   // eslint-disable-next-line consistent-return
  //   return item;
  // };

  const validate = () => {
    const keys = {};
    setTaskData({
      ...taskData,
      errors: {},
    });
    if (taskData.title?.length < 5) {
      keys.title = "Please check value.";
    }
    if (taskData.description?.length < 5) {
      keys.description = "Please check value.";
    }
    if (!taskData.dueDate) {
      keys.dueDate = "Please check value.";
    }
    if (!taskData.assignEmailTo) {
      keys.assignEmailTo = "Please check value.";
    }
    // if (!taskData.pickTime) {
    //   keys.pickTime = 'Please check value.';
    // }
    // if (taskData.pickHours <= 12) {
    //   keys.pickHours = 'Please enter value less than 12 or equal 12.';
    // }
    if (Object.keys(keys).length) {
      setTaskData({
        ...taskData,
        errors: keys,
      });
      showNotification({
        color: "red",
        title: "Task Create",
        message: "Make sure all fields are filled properly.",
      });
      return false;
    }
    // if (!taskData.assignedToId) {
    //   showNotification({
    //     color: 'red',
    //     title: 'Task Create',
    //     message: 'Assign task to a user.',
    //   });
    //   return false;
    // }
    return true;
  };

  // const usersFromStore = useSelector(selectAllWorkspaceUsers);

  // const getUserIdFromEmail = (email) => {
  //   const foundUser = usersFromStore.filter(
  //     (user) => user.email === email,
  //   );
  //   if (foundUser.length) {
  //     return foundUser[0];
  //   }
  //   return null;
  // };

  const saveHandler = async () => {
    if (validate()) {
      const taskDataForApi = {
        parentId,
        parent,
        title: taskData.title,
        parentTitle: title,
        description: taskData.description,
        // assignedToUserId: getUserIdFromEmail(taskData.assignedToId).id,
        assignEmailTo: taskData.assignEmailTo,
        // dueDate: formatISO(taskData.dueDate),
        dueDate: formatISO(finalDueDate),
        // reminderTime: formatISO(subSeconds(taskData.dueDate, taskData.reminderTime)),
        reminderTime: formatISO(
          subSeconds(finalDueDate, taskData.reminderTime)
        ),
        files: multiUploadArgs.finalFiles,
        // pickTime: taskData.pickTime,
      };
      setTaskData({
        ...taskData,
        loading: loadingStates.LOADING,
      });
      const resp = await apiWrapWithErrorWithData(
        createTaskApi({ taskData: taskDataForApi })
      );
      if (resp?.success) {
        showNotification({
          color: "green",
          title: "Task Created.",
          message: "Task has been created and assigned.",
        });
        onModalExit();
      } else {
        showNotification({
          color: "red",
          title: "Task Creation.",
          message: "An error occured.",
        });
        setTaskData({
          ...taskData,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        });
      }
    }
  };

  const getTaskEmail = async () => {
    const resp = await apiWrapWithErrorWithData(getTaskAssignedEmails());
    if (resp?.success && resp?.emailsAssigned) {
      // console.log(resp.emailsTo);
      setTaskData((stateC) => ({
        ...stateC,
        emails: resp.emailsAssigned,
      }));
    } else {
      // showNotification({
      //   title: 'Emails',
      //   message: 'Could not load emails',
      //   color: 'red',
      // });
    }
  };

  useEffect(() => {
    getTaskEmail();
  }, []);

  return (
    <div className="flex flex-col px-10">
      <div className="flex flex-row justify-between">
        <div className={`${styles.taskHeader}`}>
          Create task for {/* {parent.toUpperCase()} */}
          {title?.toUpperCase()}
          {/* {parentId} */}
        </div>
      </div>
      <div className={`${styles.taskTitle} mt-2`}>
        <ul>
          <li>Keep task title small and clear.</li>
          <li>Attach files as necessary.</li>
          <li>Provide clear directions for assignee in description field.</li>
        </ul>
      </div>
      <div className="mt-5">
        <TextInput
          placeholder="Title"
          label="Title"
          required
          value={taskData.title}
          onChange={changeHandler("title")}
          error={taskData.errors.title}
        />
      </div>
      <div className="mt-5">
        <Textarea
          minRows={10}
          placeholder="Description"
          label="Description"
          value={taskData.description}
          onChange={changeHandler("description")}
          error={taskData.errors.description}
        />
      </div>
      <div className="grid gap-4 grid-cols-2  mt-5">
        {/* <UserSelector
          onChange={changeHandler('assignedToId')}
          value={taskData.assignedToId}
          label="Assign to"
          placeholder="Type name or email"
        /> */}
        <Select
          label="Assign to"
          placeholder="Type name or email"
          data={[...totalEmail, ...taskData.emails]}
          // value={taskData.assignedEmail}
          searchable
          onChange={changeHandler("assignEmailTo")}
          creatable
          // getCreateLabel={(query) => `+ Create ${query}`}
          getCreateLabel={emailCreateLabelHandler}
          onCreate={(query) => {
            const item = { value: query, label: query };
            setTotalEmail((current) => [...current, query]);
            return item;
          }}
          error={taskData.errors.assignedEmail}
        />
        <DatePicker
          minDate={new Date()}
          placeholder="Due date"
          label="Select date"
          value={taskData.dueDate}
          onChange={changeHandler("dueDate")}
          error={taskData.errors.dueDate}
        />
        <div style={{ marginTop: "4px" }}>
          <div className="font-semibold">Pick deadline</div>
          <div className="flex flex-row">
            <div className="flex flex-row items-center">
              <TextInput
                label=""
                onChange={changeHandler("pickHours")}
                value={taskData.pickHours}
                style={{ width: "50px" }}
              />
              {" : "}
            </div>{" "}
            <div className="flex flex-row items-center">
              <TextInput
                label=""
                onChange={changeHandler("pickMinutes")}
                value={taskData.pickMinutes}
                style={{ width: "50px" }}
              />
            </div>
            <div style={{ width: "70px" }} className="ml-2">
              <Select
                // clearable
                data={[
                  { value: "am", label: "am" },
                  { value: "pm", label: "pm" },
                ]}
                value={taskData.pickInterval}
                onChange={changeHandler("pickInterval")}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "4px" }}>
          <div className="font-semibold">Reminder time</div>
          <div className="flex flex-row">
            <div className="flex flex-row items-center">
              <TextInput
                label=""
                onChange={changeHandler("reminderHours")}
                value={taskData.reminderHours}
                style={{ width: "60px" }}
              />
              {" : "}
              <span className="font-semibold">hours</span>
            </div>{" "}
            <div className="flex flex-row items-center">
              <TextInput
                label=""
                onChange={changeHandler("reminderMinutes")}
                value={taskData.reminderMinutes}
                style={{ width: "60px" }}
              />
              {" : "}
              <span className="font-semibold">minutes</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.file} mt-6 mb-1 font-bold`}>Files</div>
      <NewDocumentUploader multiUploadArgs={multiUploadArgs} />
      <div className="flex justify-end my-8">
        <Button
          onClick={saveHandler}
          disabled={taskData.loading === loadingStates.LOADING}
          style={{
            backgroundColor: "#46BDE1",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
        >
          {taskData.loading === loadingStates.LOADING && (
            <BeatLoader color={colors.primary} size={10} />
          )}
          {taskData.loading === loadingStates.NO_ACTIVE_REQUEST && (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}

export default TaskNewForm;
