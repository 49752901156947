import { Skeleton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { getAgreementByStatus } from '../../utilities/apis/agreements';
import { loadingStates } from '../../utilities/utilities';

function AgreementGraph() {
  const [configs, setConfigs] = useState({
    listLoading: loadingStates.NO_ACTIVE_REQUEST,
    agreementsLoading: loadingStates.NO_ACTIVE_REQUEST,
    list: null,
    agreementsList: null,
    // module: 'Case',
  });
  const fetchAgreementsByStatus = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      agreementsLoading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(getAgreementByStatus());
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        agreementsLoading: loadingStates.NO_ACTIVE_REQUEST,
        agreementsList: resp.getAgreement,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        agreementsLoading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: 'Cases',
      //   message: "Couldn't load Monthly Cases",
      //   color: 'red',
      // });
    }
  };

  useEffect(() => {
    fetchAgreementsByStatus();
  }, []);
  return (
    <div className="pb-12">
      <div
        className="border-solid border-gray-100 border-2 rounded-lg p-4 mt-4"
        style={{ border: 'none', boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1)', width: '100%' }}
      >
        <div className="mt-2">
          Agreements Signed Month wise
        </div>
        {configs.agreementsLoading === loadingStates.LOADING
            && <Skeleton style={{ height: '200px' }} className="mt-4 mx-8" visible />}
        {configs.agreementsLoading === loadingStates.NO_ACTIVE_REQUEST && configs.agreementsList
              && (
              <div
                className="mt-4 w-full"
                style={{
                  height: '200px',
                  width: '100%',
                }}
              >
                <Bar
                  data={{
                    labels: configs.agreementsList.map((el) => `${el.month} ${el.year}, No. of Agreement ${el['_count'].id}`),
                    datasets: [
                      {
                        label: 'Agreement Signed',
                        backgroundColor: '#804674',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.agreementsList.map((el) => el['_avg'].noOfCompletionDays),
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: 'Average Time(in days)',
                        },
                      },
                      x: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          // text: 'No. of Agreement Completed Month wise',
                          text: 'No. of Agreement Signed Month wise',
                        },
                      },
                    },
                  }}
                />
              </div>
              )}
      </div>
    </div>
  );
}

export default AgreementGraph;
