/** @format */

import { formatDate, formatTime } from "../../utilities/utilities";
import styles from "../../components/TemplateManager/TemplateRow.module.css";
import CopyComponent from "../../components/CopyComponent/CopyComponent";

import React from "react";
import { Textarea, Text, Button, MultiSelect } from "@mantine/core";
import { MdDelete } from "react-icons/md";

export function ClauseDetailsRow(props) {
  return (
    <div className="my-4">
      <div className="flex justify-between">
        <div>
          <Text className="my-2">
            {props.item.title.toUpperCase()}
            <span className={`${styles.date} mx-2`}>created At</span>
            <span className={`${styles.date}`}>
              {formatDate(props.item.createdAt)}
            </span>
            <span className={`${styles.date} mx-2`}>
              {formatTime(props.item.createdAt)}
            </span>
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            className="mx-3"
            size="xs"
            onClick={() => {
              props.editClause(props.item.id);
            }}
          >
            Edit
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdDelete size={25} onClick={() => props.setOpen(true)} />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Textarea value={props.item.text} readOnly maxRows={5} style={{ width: "97%" }}/>
        <CopyComponent text={props.item.text} />
      </div>
      <MultiSelect
        className="my-2"
        readOnly
        data={props.item.clauseTag.map((a) => ({
          label: a.tag.title,
          value: a.tag.title,
        }))}
        onChange={() => {}}
        defaultValue={props.item.clauseTag.map((a) => a.tag.title)}
      />
    </div>
  );
}
