/** @format */

import { Stepper, Text } from "@mantine/core";

function AgreementBulkSteps() {
  return (
    <div>
      <div className="mb-3">
        <Text>Note:- </Text>
        <Stepper>
          <Stepper.Step label="Info" />
          <Stepper.Step label="Download CSV" />
          <Stepper.Step label="Fill" />
          <Stepper.Step label="Upload & Send" />
        </Stepper>
      </div>
    </div>
  );
}

export default AgreementBulkSteps;
