/** @format */

import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

import React, { Fragment } from "react";
import { ClauseDetailsRow } from "./ClauseDetailsRow";

function ClauseDetails({
  item,
  deleteClauseApi,
  clauseModalConfig,
  editClause,
  open,
  setOpen,
}) {
  return (
    <Fragment>
      <ClauseDetailsRow 
        item={item} 
        editClause={editClause} 
        setOpen={setOpen} 
      />
      <DeleteConfirmationModal
        open={open}
        setOpen={setOpen}
        deleteHandler={() => deleteClauseApi(item.id)}
        deleteLoading={clauseModalConfig.deleteLoading}
        text={"Do you want to delete this clause?"}
      />
    </Fragment>
  );
}

export default ClauseDetails;
