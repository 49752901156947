import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { Edit, File, Ban } from "tabler-icons-react";
import { Table, ActionIcon } from "@mantine/core";
import { getInvoiceDataAPI } from "../../utilities/apis/recovery";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { formatDate } from "../../utilities/utilities";
import { getReadPresignedUrl } from "../../utilities/apis/commonApis";
import { disableInvoice } from "../../utilities/apis/recovery";

function InvoiceListSection({ dueDetailId, invoiceData = {}, setInvoiceData = {}, setDueDetail = {} }) {
    const [invList, setInvList] = useState([]);
    const fetchInvoices = async () => {

        const response = await apiWrapWithErrorWithData(
            getInvoiceDataAPI({ dueDetailId })
        );
        if (response.success) {
            setInvList(response.data);
        }
    };
    const handleFileOpening = async (row) => {
        const response = await apiWrapWithErrorWithData(
            getReadPresignedUrl({
                hostedLink: row.invoiceCopyUrl,
                parentId: row.id,
                parent: "invoiceData",
            })
        );
        if (response?.success && response?.signedUrl) {
            window.open(response.signedUrl, "_blank").focus();
        }
    };
    const handleInvoiceEdit = (row) => {
        setInvoiceData((prevState) => ({
            ...prevState,
            isModalOpen: true,
            isEdit: true,
            id: row.id,
            number: row.invoiceNumber,
            date: new Date(row.dueDate),
            amount: row.invoiceAmount,
            currencyType: row.currencyType,
            creditPeriod: row.creditPeriod,
            penaltyRate: row.penaltyRate,
            files: "",
        }));
    };
    const handleInvoiceDisable = async (row) => {
        const response = await apiWrapWithErrorWithData(
            disableInvoice({
                id: row.id,
                dueDetailId
            })
        );
        if (response.success) {
            fetchInvoices();
            setDueDetail((prevState) => ({
                ...prevState,
                recoveryAmount: response.data.recoveryAmount,
                currencyType: response.data.currencyType,
                noOfInvoice: response.data.noOfInvoice,
                firstInvDate: response.data.firstInvDate,
            }));
        }
    }
    const getAgeingDays = (dueDate, creditPeriod) => {
        const dueDateObj = new Date(dueDate);
        dueDateObj.setDate(dueDateObj.getDate() + creditPeriod);
        dueDateObj.setUTCHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setUTCHours(0, 0, 0, 0);
        const diffTime = Math.abs(currentDate - dueDateObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    useEffect(() => {
        fetchInvoices();
    }, [invoiceData.isModalOpen]);

    return (
        <>
            {invList.length > 0 && (
                <Table striped highlightOnHover={true} className={Object.keys(invoiceData).length !== 0 ? "mt-10" : "mt-5"}>
                    <thead>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Invoice Date</th>
                            <th>Amount</th>
                            <th>Currency Type</th>
                            <th>Penalty Rate per annum</th>
                            <th>Credit Period</th>
                            <th>Ageing</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invList.map((invoice) => (
                            <tr key={invoice.id}>
                                <td>{invoice.invoiceNumber}</td>
                                <td>{formatDate(invoice.dueDate)}</td>
                                <td>{invoice.invoiceAmount}</td>
                                <td>{invoice.currencyType}</td>
                                <td>{invoice.penaltyRate}</td>
                                <td>{invoice.creditPeriod}</td>
                                <td>{`${getAgeingDays(invoice.dueDate, invoice.creditPeriod)} Days`}</td>
                                <td>
                                    <div className="grid grid-cols-2">
                                        <ActionIcon
                                            title="Open"
                                            onClick={() => handleFileOpening(invoice)}
                                        >
                                            <File />
                                        </ActionIcon>
                                        {Object.keys(invoiceData).length !== 0 && (
                                            <ActionIcon
                                                title="Disable"
                                                onClick={() => handleInvoiceDisable(invoice)}
                                            >
                                                <Ban color="red" />
                                            </ActionIcon>
                                        )}
                                        {/* <ActionIcon
                                            title="Edit"
                                            onClick={() => handleInvoiceEdit(invoice)}
                                        >
                                            <Edit color="red" />
                                        </ActionIcon> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}

export default InvoiceListSection;
