import React from 'react';
import { Button } from '@mantine/core';
import styles from './SearchOption.module.css';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utilities/utilities';

function SearchOption({
  backgroundColor, module, link, id, status, title, type, date
}) {
  const navigate = useNavigate();
  const bg = {
    backgroundColor,
  };
  function validateAndFormatDate(date) {
   // console.log("Received date:", date); // Add this line for debugging
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Date not available';
    }
    return formatDate(date);
  }
  
  console.log("link :",link)
  console.log("id :",id)
  return (
    <div style={bg} className={`${styles.bgRed} pl-4 my-3`}>
      <div className={`${styles.topBox} px-6 pt-2 flex justify-between`}>
        <div className='flex my-2' style={{ width: '100%'}}>
          <div className={`${styles.task}`} style={{ marginRight:'15px', width: '18%'}}>{module}</div>
          <div className='flex mx-2' style={{ width: '20%'}}>
            <div className={`${styles.task} mx-2`} style={{ fontSize: '13px'}}>id</div>
            <div className={`${styles.heading}`}>{id ? id : 'NIL'}</div>
          </div>
          <div className='flex mx-2' style={{ width: '23%'}}>
            <div className={`${styles.task} mx-2`} style={{ fontSize: '13px'}}>title</div>
            <div className={`${styles.heading}`}>{title ? title : 'NIL'}</div>
          </div>
          <div className='flex mx-2' style={{ width: '23%'}}>
            <div className={`${styles.task} mx-2`} style={{ fontSize: '13px'}}>type</div>
            <div className={`${styles.heading}`}>{type ? type: 'NIL'}</div>
          </div>
          <div className='flex mx-2' style={{ width: '23%'}}>
            <div className={`${styles.task} mx-2`} style={{ fontSize: '13px'}}>Date</div>
            <div className={`${styles.heading}`}>{validateAndFormatDate(date)}</div>
          </div>
          <div className='flex mx-2' style={{ width: '23%'}}>
            <div className={`${styles.task} mx-2`} style={{ fontSize: '13px'}}>status</div>
            <div className={`${styles.heading}`}>{status ? status: 'NIL'}</div>
          </div>
        </div>
        <div className='flex'>
          <div className="flex justify-center">
            <Button
              onClick={() => navigate(`${link}/${id}`)}
              compact
              style={{
                backgroundColor,
                borderRadius: '0.5rem',
                color: '#F5F5F5',
              }}
            >
              More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchOption;
