/** @format */

import axios from "axios";
import { headersProvider } from "../apiHelpers";
import constants from "../constants";

export const getAllBlogs = ({ page }) =>
  axios.get(`${constants.utilityHost}/blogs/getAll/${page}`, {
    headers: headersProvider(),
  });

export const createBlog = ({ data }) =>
  axios.post(
    `${constants.utilityHost}/blogs/create`, data, {
      headers: { ...headersProvider(), "Content-Type": "multipart/form-data" },
    });

export const getSingleBlog = ({ blogId }) =>
  axios.get(`${constants.utilityHost}/blogs/${blogId}`, {
    headers: headersProvider(),
  });

export const editBlog = ({ blogId, title, content }) =>
  axios.put(
    `${constants.utilityHost}/blogs/update/${blogId}`,
    {
      title,
      content,
    },
    {
      headers: headersProvider(),
    }
  );

export const deleteBlog = ({ blogId }) =>
  axios.put(`${constants.utilityHost}/blogs/delete/${blogId}`, {
    headers: headersProvider(),
  });
