/**
 * eslint-disable max-len
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { showNotification } from "@mantine/notifications";
import { Grid, Skeleton } from "@mantine/core";
import { loadingStates } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { agreementAnalytics } from "../../utilities/apis/agreements";
import {
  customChartData,
  customChartDataForLocation,
} from "../../utilities/utilities";
import DoughnutChart from "../doughnutChart/doughnutChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const AgreementAnalytics = () => {
  const [configs, setConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    data: null,
  });

  useEffect(() => {
    (async () => {
      setConfigs({ ...configs, loading: loadingStates.LOADING });
      const resp = await apiWrapWithErrorWithData(agreementAnalytics());
      if (resp?.success && resp?.analytics) {
        setConfigs({
          ...configs,
          data: resp.analytics,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        });
      } else {
        // showNotification({
        //   color: 'red',
        //   title: 'Agreement Analytics',
        //   message: 'Failed to load agreement analytics.',
        // });
        setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
      }
    })();
  }, []);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxHeight: 10,
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <div className="w-full">
      <Grid>
        {configs.loading === loadingStates.LOADING && (
          <>
            <Grid.Col span={4}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
            <Grid.Col span={4}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
            <Grid.Col span={4}>
              <Skeleton style={{ height: "310px" }} className="mx-8" visible />
            </Grid.Col>
          </>
        )}
        {configs.loading !== loadingStates.LOADING &&
          configs.data?.agreementsStatusWise && (
            <>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>Agreements by status</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChart
                      chartOptions={chartOptions}
                      method={customChartData("status")}
                      data={configs?.data?.agreementsStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>Agreements tasks by status</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChart
                      chartOptions={chartOptions}
                      method={customChartData("status")}
                      data={configs?.data?.agreementsTaskStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>Agreements by type</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChart
                      chartOptions={chartOptions}
                      method={customChartData("typeOfAgreement")}
                      data={configs?.data?.agreementTypeWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={12}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{
                    boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1) ",
                  }}
                >
                  <div>Agreements by location</div>
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      padding: "16px",
                      position: "relative",
                    }}
                  >
                    <DoughnutChart
                      chartOptions={chartOptions}
                      method={customChartDataForLocation("location")}
                      data={configs?.data?.agreementsLocationWise}
                    />
                  </div>
                </div>
              </Grid.Col>
            </>
          )}
      </Grid>
    </div>
  );
};

export default AgreementAnalytics;