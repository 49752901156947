/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { showNotification } from '@mantine/notifications';
import { Grid, Skeleton, Divider } from '@mantine/core';
import { loadingStates } from '../utilities/utilities';
import { apiWrapWithErrorWithData } from '../utilities/apiHelpers';
import { tasksAnalytics } from '../utilities/apis/tasks';
import { customChartData } from '../utilities/utilities';
import DoughnutChart from '../components/doughnutChart/doughnutChart';
// import { casesAnalytics } from '../utilities/apis/cases';

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardAnalytics = ({ month }) => {
  const [configs, setConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    data: null,
  });
  useEffect(() => {
    (async () => {
      setConfigs({ ...configs, loading: loadingStates.LOADING });
      const resp = await apiWrapWithErrorWithData(tasksAnalytics({ month }));
      if (resp?.success && resp?.analytics) {
        setConfigs({
          ...configs,
          data: resp.analytics,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        });
      } else {
        // showNotification({
        //   color: 'red',
        //   title: 'Case Analytics',
        //   message: 'Failed to load case analytics.',
        // });
        setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
      }
    })();
  }, [month]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxHeight: 10,
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <div className="w-full">
      <Grid>
        {configs.loading === loadingStates.LOADING
          && (
            <>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
              <Grid.Col span={4}>
                <Skeleton style={{ height: '310px' }} className="mx-8" visible />
              </Grid.Col>
            </>
          )}
        {(configs.loading !== loadingStates.LOADING && configs.data?.taskStatusWise)
          && (
            <>
              <Grid.Col span={4}>
                <div
                  // className="border-solid border-gray-100 border-2 rounded-lg p-4"
                  className="border-2 border-none rounded-lg p-4"
                  style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
                >
                  <div>Tasks</div>
                  <div
                    style={{
                      height: '200px',
                      width: '100%',
                      padding: '16px',
                      position: 'relative'
                    }}
                  >
                    {/* <Doughnut
                      data={taskStatusWise(configs.data.taskStatusWise)}
                      options={chartOptions}
                    />
                    <div style={{ position: 'absolute', width: '100%', top: '50%', left: '140px', marginTop: '-14px',  lineHeight: '20px'}}>
                      <span>{totalCountChart(configs.data.taskStatusWise)}</span>
                    </div> */}
                    <DoughnutChart 
                      chartOptions={chartOptions} 
                      method={customChartData('status')} 
                      data={configs.data.taskStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
                >
                  <div>Agreements</div>
                  <div
                    style={{
                      height: '200px',
                      width: '100%',
                      padding: '16px',
                      position: 'relative'
                    }}
                  >
                    <DoughnutChart 
                      chartOptions={chartOptions} 
                      method={customChartData('status')} 
                      data={configs.data.agreementStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
                >
                  <div>Notices</div>
                  <div
                    style={{
                      height: '200px',
                      width: '100%',
                      padding: '16px',
                      position: 'relative'
                    }}
                  >
                    <DoughnutChart 
                      chartOptions={chartOptions} 
                      method={customChartData('status')} 
                      data={configs.data.noticeStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
                >
                  <div>Cases</div>
                  {/* <div>Notice by status</div> */}
                  <div
                    style={{
                      height: '200px',
                      width: '100%',
                      padding: '16px',
                      position: 'relative'
                    }}
                  >
                    <DoughnutChart 
                      chartOptions={chartOptions} 
                      method={customChartData('status')} 
                      data={configs.data.casesStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={4}>
                <div
                  className="border-2 border-none rounded-lg p-4"
                  style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
                >
                  <div>Service Providers</div>
                  {/* <div>Notice by status</div> */}
                  <div
                    style={{
                      height: '200px',
                      width: '100%',
                      padding: '16px',
                      position: 'relative'
                    }}
                  >
                    <DoughnutChart 
                      chartOptions={chartOptions} 
                      method={customChartData('designation')} 
                      data={configs.data.serviceProviderStatusWise}
                    />
                  </div>
                </div>
              </Grid.Col>
            </>
          )}
      </Grid>
      <Divider style={{ marginTop: '25px', marginBottom: '20px'}}/>
      {/* <div>
      {configs.loading !== loadingStates.LOADING && configs.data?.taskStatusWise ? 
        <Grid>
          <Grid.Col span={6}>
            <div
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div
                style={{
                  width: '100%',
                  padding: '16px',
                }}
              >
                <Bar
                  data={{
                    labels: configs.data.agreementAverageCompletionTime?.map((el) => `${el.month} ${el.year}`),
                    datasets: [
                      {
                        label: 'Monthlywise Average Agreement Completion(in days)',
                        backgroundColor: '#cc5de8',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.data.agreementAverageCompletionTime?.map((el) => el['_avg'].noOfCompletionDays),
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'MONTHLY ACTIVITIES',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={6}>
            <div
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div
                style={{
                  width: '100%',
                  padding: '16px',
                }}
              >
                <Bar
                  data={{
                    labels: configs.data.inwardNoticesAverageCompletionTime?.map((el) => `${el.month} ${el.year}`),
                    datasets: [
                      {
                        label: 'Monthlywise Average Inward Notices Completion(in days)',
                        backgroundColor: '#46BDE1',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.data.inwardNoticesAverageCompletionTime?.map((el) => el['_avg'].noOfCompletionDays),
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'MONTHLY ACTIVITIES',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={6}>
            <div
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div
                style={{
                  width: '100%',
                  padding: '16px',
                }}
              >
                <Bar
                  data={{
                    labels: configs.data.outwardNoticesAverageCompletionTime?.map((el) => `${el.month} ${el.year}`),
                    datasets: [
                      {
                        label: 'Monthlywise Average Outward Notices Completion(in days)',
                        backgroundColor: '#46BDE1',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.data.outwardNoticesAverageCompletionTime?.map((el) => el['_avg'].noOfCompletionDays),
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'MONTHLY ACTIVITIES',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={6}>
            <div
              className="border-2 border-none rounded-lg p-4"
              style={{ boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1) ' }}
            >
              <div
                style={{
                  width: '100%',
                  padding: '16px',
                }}
              >
                <Bar
                  data={{
                    labels: configs.data.casesAverageCompletionTime?.map((el) => `${el.month} ${el.year}`),
                    datasets: [
                      {
                        label: 'Monthlywise Average Cases Completion(in days)',
                        backgroundColor: '#1C7ED6',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.data.casesAverageCompletionTime?.map((el) => el['_avg'].noOfCompletionDays),
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: true,
                        text: 'MONTHLY ACTIVITIES',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Grid.Col>
        </Grid> : null}
      </div> */}
      <Divider style={{ marginTop: '25px', marginBottom: '20px'}}/>
    </div>
  );
};
export default DashboardAnalytics;
