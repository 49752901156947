import axios from 'axios';
import { apiWrapWithErrorWithData, headersProvider } from '../apiHelpers';
import constants from '../constants';

export const createAgreement = ({
  agreementData, ...restArgs
}) => axios.post(`${constants.utilityHost}/agreements/new-agreement`, {
  agreementData, ...restArgs,
},
{
  headers: headersProvider(),
});

export const getAgreementsList = ({
  page,pageSize, sortByOptions, filterOptions,
}) => axios.post(`${constants.utilityHost}/agreements/list`, {
  page,pageSize,
  filterOptions,
  sortByOptions,
}, {
  headers: headersProvider(),
});

export const updateAgreement = ({
  agreementId, agreementData, noOfCompletionDays, ...restArgs
}) => axios.put(`${constants.utilityHost}/agreements/update/${agreementId}`, {
  agreementData, ...restArgs, noOfCompletionDays,
}, {
  headers: headersProvider(),
});

export const getAgreementDetails = ({
  agreementId,
}) => axios.get(`${constants.utilityHost}/agreements/details/${agreementId}`, {
  headers: headersProvider(),
});
export const getEmsignerLogs = async ({ agreementId }) =>
 await axios.get(
    `${constants.utilityHost}/agreement/Emsigner-Logs/${agreementId}`,
    {
      headers: headersProvider(),
    }
  );
export const sendReminderId = ({
  signatureId,
}) => axios.get(`${constants.utilityHost}/agreements/details/sendReminder/${signatureId}`, {
  headers: headersProvider(),
});

// export const deleteAgreementDetails = ({
//   agreementId,
// }) => axios.put(`${constants.utilityHost}/agreements/delete`, {
//   agreementId,
// }, {
//   headers: headersProvider(),
// });

export const deleteAgreementBulk = ({
  agreementIds,
}) => axios.put(`${constants.utilityHost}/agreements/delete`, {
  agreementIds,
}, {
  headers: headersProvider(),
});

export const createSigningRequest = (emails) => axios.post('https://w6bduqd0tj.execute-api.us-east-1.amazonaws.com/default/signer-proxy', {
  emails,
}, {
  noTrailingSlash: true,
});

const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

export const getPreviewUrl = async (delta) => {
  let url = null;
  const data = await apiWrapWithErrorWithData(axios.post(`${constants.utilityHost}/utilities/common/get-pdf-in-base64`, {
    quillDelta: delta,
  }));
  if (data.success && data.fileString) {
    url = await b64toUrl(data.fileString);
  }
  return { url, base64File: data?.fileString };
};

export const getAgreementSignStatusApi = ({ agreementId }) => axios.get(`${constants.utilityHost}/agreements/sign-details/${agreementId}`, {
  headers: headersProvider(),
});

export const sendAgreementForSignature = ({ signers, agreementId, ...params }) => axios.post(`${constants.utilityHost}/agreements/sign-initiate/${agreementId}`, {
  ...params,
  signers,
}, {
  headers: headersProvider(),
});

export const cancelAgreementSignature = ({ agreementId }) => axios.post(`${constants.utilityHost}/agreements/sign-cancel/${agreementId}`, {}, {
  headers: headersProvider(),
});

export const agreementAnalytics = () => axios.get(`${constants.utilityHost}/agreements/analytics`, {
  headers: headersProvider(),
});

export const getAgreementTypesApi = () => axios.get(`${constants.utilityHost}/agreements/get-distinct-types`, {
  headers: headersProvider(),
});

export const getAgreementTitleApi = () => axios.get(`${constants.utilityHost}/agreements/get-distinct-titles`, {
  headers: headersProvider(),
});

export const getAgreementByStatus = () => axios.get(`${constants.utilityHost}/agreements/get-agreement-by-status`, {
  headers: headersProvider(),
});

export const createEditorField = ({ fieldName, agreementId }) => axios.post(`${constants.utilityHost}/agreements/editor-field/${agreementId}`, { fieldName },
  {
    headers: headersProvider(),
  });
export const deleteEditorField = ({ fieldName, agreementId }) => axios.put(`${constants.utilityHost}/agreements/editor-field/${agreementId}`, { fieldName },
  {
    headers: headersProvider(),
  });

export const getEditorField = ({ agreementId }) => axios.get(`${constants.utilityHost}/agreements/editor-field/${agreementId}`,
  {
    headers: headersProvider(),
  });

export const getUser = () => axios.get(`${constants.utilityHost}/agreements/user/details`,
  {
    headers: headersProvider(),
  });

export const getRegisterUserApi = () => axios.get(`${constants.utilityHost}/agreements/register-user`,
  {
    headers: headersProvider(),
  });

  export const bulkAgreementUploadApi = ({ formData }) => axios.post(`${constants.utilityHost}/agreements/bulk-agreement/create`,
  formData, {
    headers: {
      ...headersProvider(),
      'Content-Type': 'multipart/form-data'
    },
  });

  export const getAgreementsCsvDownload = ({
    page, sortByOptions, filterOptions,
  }) => axios.post(`${constants.utilityHost}/agreement/csv`, {
    page,
    filterOptions,
    sortByOptions,
  }, {
    headers: headersProvider(),
  });

  export const getAgreementCrt = ({ agreementId }) => axios.get(`${constants.utilityHost}/agreement/Pdf-Certificate/${agreementId}`,
  {
    headers: headersProvider(),
  });

