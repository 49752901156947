/** @format */

import React from "react";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./TemplateRow.module.css";
import { formatDate, formatTime } from "../../utilities/utilities";
import { downloadCsvApi } from "../../utilities/apis/templates";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { selectUserData } from "../../redux/selectors";
import { useState } from "react";
import { useEffect } from "react";
import { Badge } from "@mantine/core";

function TemplateRow({
  createdBy,
  createdAt,
  category,
  name,
  title,
  editButton = false,
  viewButton = true,
  onDelete,
  onViewClick,
  id,
  module,
}) {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserData);
  console.log(userDetails?.designation);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (deleteLoading) {
      onDelete(id);
    }
  }, [deleteLoading]);

  const editHandler = () => {
    module === "clause"
      ? navigate(`/app/templates/clause/${id}`)
      : navigate(`/app/templates/edit/template/${id}`);
  };

  const downloadCsvHandler = async () => {
    const res = await apiWrapWithErrorWithData(
      downloadCsvApi({ templateId: id })
    );
    if (res.success) {
      const { data } = res;
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += data.fieldName ? data.fieldName?.join(",") : "";

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${data.name}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="flex flex-row justify-between pr-10 mb-14">
      <div className="flex flex-row text-center">
        <div>
          <img src="/assets/images/downloadFileLogo.svg" alt="fileLogo" />
        </div>
        <div className="flex flex-col pl-3">
          <div className={`flex justify-start ${styles.agreementTitle}`}>
            {name ? name : title}{" "}
            {category && (
              <Badge
                className="text-s mx-3 mb-2 lowercase max-w-[100px] truncate"
                title="Category"
              >
                {category}
              </Badge>
            )}
          </div>
          <div className="flex flex-row text-center ">
            <div className={`${styles.text} mr-3`}>
              Created by {createdBy.name}
            </div>
            <div className="flex flex-row text-center">
              <div>
                <img src="/assets/images/clock2.svg" alt="clock" />
              </div>
              <div className={`${styles.date} ml-2`}>
                {formatDate(createdAt)},{formatTime(createdAt)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        {viewButton && (
          <Button
            onClick={onViewClick}
            className="mx-2"
            variant="outline"
            color="cyan"
            style={{
              // backgroundColor: '#46BDE1',
              color: "#46BDE1",
            }}
          >
            View
          </Button>
        )}
        {userDetails?.designation === "Admin" ? (
          <Button
            disabled={deleteLoading}
            style={{
              marginRight: "5px",
              backgroundColor: deleteLoading ? "#d9d9d9" : "#ff4d4d",
              borderRadius: "0.25rem",
              height: "40px",
              fontWeight: "400",
              lineHeight: "1.375rem",
            }}
            onClick={() => {
              setDeleteLoading(true);
            }}
          >
            Delete
          </Button>
        ) : null}
        {editButton && (
          <Button
            onClick={editHandler}
            style={{
              backgroundColor: "#46BDE1",
              borderRadius: "0.25rem",
              height: "40px",
              fontWeight: "400",
              lineHeight: "1.375rem",
            }}
          >
            Edit
          </Button>
        )}
        {/* {editButton
            && (
            <Button
              onClick={downloadCsvHandler}
              style={{
                marginLeft: '10px',
                backgroundColor: ' #46BDE1',
                borderRadius: '0.25rem',
                height: '40px',
                fontWeight: '400',
                lineHeight: '1.375rem',
              }}
            >
              CSV download
            </Button>
            )} */}
      </div>
    </div>
  );
}

export default TemplateRow;
