import React from 'react';
import {
  Button, Divider,
  Select, Skeleton,
  Text,
  TextInput,
} from '@mantine/core';
import flat from 'flat';
import { showNotification } from '@mantine/notifications';
import { BeatLoader } from 'react-spinners';
import { loadingStates, validateEmail, validateMobile } from '../../utilities/utilities';
import colors from '../../utilities/design';

const AgreementForm1 = ({
  form, setUiConfigs, uiConfigs, save,
}) => {
  const agreementFormJson = flat.unflatten(form.values);

  const saveNext = async () => {
    const keys = {};
    if (!agreementFormJson?.typeOfAgreement || agreementFormJson?.typeOfAgreement?.length < 3) {
      keys.typeOfAgreement = 'Type is mandatory.';
    }
    if (!agreementFormJson?.titleOfAgreement || agreementFormJson?.titleOfAgreement?.length < 3 ) {
      keys.titleOfAgreement = 'Title is mandatory.';
    }
    if (!agreementFormJson?.titleOfAgreement || agreementFormJson?.titleOfAgreement?.length > 20) {
      keys.titleOfAgreement = 'The title for the agreement should stay within 20 characters or less.'; 
    }
    
    
   
    if (agreementFormJson?.requestingUser?.name?.length < 3) {
      keys['requestingUser.name'] = 'Please enter 3 or more characters.';
    }
    if (agreementFormJson?.requestingUser?.email?.length > 0) {
      if (!validateEmail(agreementFormJson?.requestingUser?.email)) {
        keys['requestingUser.email'] = 'Please enter correct email.';
      }
    } else {
      keys['requestingUser.email'] = 'Please enter correct email.';
    }
    if (agreementFormJson?.requestingUser?.phone?.length > 0) {
      if (!validateMobile(agreementFormJson?.requestingUser?.phone)
      || agreementFormJson?.requestingUser?.phone.length !== 10
      ) {
        keys['requestingUser.phone'] = 'Please enter 10 digits phone number.';
      }
    } else {
      keys['requestingUser.phone'] = 'Please enter 10 digits phone number.';
    }
    if (Object.keys(keys).length > 0) {
      form.setErrors(keys);
      showNotification(({
        color: 'red',
        title: 'Agreement Form',
        message: 'Please check all fields are filled properly.',
      }));
    } else {
      await save();
      setUiConfigs({
        ...uiConfigs,
        currentStep: 1,
      });
    }
  };

  return (
    <div className="w-full flex flex-col my-4">
      {agreementFormJson.typeOfAgreementData.length === 0
      || agreementFormJson.typeOfAgreementData.length
        ? (
          <div className="grid grid-cols-2">
            <Select
              searchable
              required
              creatable
              className="max-w-lg"
              label="Type of agreement"
              placeholder='Select type of agreement'
              data={agreementFormJson.typeOfAgreementData}
              getCreateLabel={(query) => `+ Add ${query}`}
              onCreate={(query) => {
                form.setFieldValue('typeOfAgreementData', Array.from(new Set([
                  ...(form.values.typeOfAgreementData || []),
                  query,
                ])));
              }}
              {...form.getInputProps('typeOfAgreement')}
            />
            <Select
              searchable
              required
              creatable
              className="max-w-lg"
              label="Title of agreement"
              placeholder='Select title of agreement'
              data={agreementFormJson.titleOfAgreementData}
              getCreateLabel={(query) => `+ Add ${query}`}
              onCreate={(query) => {
                form.setFieldValue('titleOfAgreementData', Array.from(new Set([
                  ...(form.values.titleOfAgreementData || []),
                  query,
                ])));
              }}
              {...form.getInputProps('titleOfAgreement')}
            />
          </div>
        )
        : <Skeleton height={50} />}
      <Divider className="mt-8" />
      <Text size="md" color="#46BDE1" className="my-8">Requesting User Details</Text>
      <div className="grid grid-cols-3 gap-4">
        <TextInput required label="Name" placeholder="Enter Name" {...form.getInputProps('requestingUser.name')} />
        <TextInput required label="Email" placeholder="Enter Email" {...form.getInputProps('requestingUser.email')} />
        <TextInput label="Phone" placeholder="Enter 10 digit phone no." {...form.getInputProps('requestingUser.phone')} />
        <TextInput label="Designation" placeholder="Enter Designation" {...form.getInputProps('requestingUser.designation')} />
        <TextInput label="Department" placeholder="Enter Department" {...form.getInputProps('requestingUser.businessDepartment')} />
      </div>
      <div className="flex flex-row justify-between">
        <div />
        {uiConfigs.loading === loadingStates.LOADING
          ? <BeatLoader color={colors.primary} size={10} />
          : (
            <Button
              className="max-w-xs mt-20"
              style={{
                backgroundColor: '#46BDE1',
              }}
              onClick={saveNext}
            >
              Next
            </Button>
          ) }
      </div>
    </div>
  );
};

export default AgreementForm1;
