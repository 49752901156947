/** @format */

import React, { useState, useEffect, useRef } from "react";
import { TextInput, Button, FileInput, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { createBlog, editBlog, deleteBlog } from "../../utilities/apis/blogs";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import colors from "../../utilities/design";
import "quill/dist/quill.snow.css";

function imageHandler() {}

function CreateBlogs() {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState("");
  const [isHtml, setIsHtml] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const quillRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (quillRef != null && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");
      toolbar.addHandler("image", imageHandler.bind(toolbar));
    }
  }, []);

  const onChange = async () => {
    const quillInstance = quillRef.current.getEditor();
    // quillInstance.getContents();  // This is a Quill Delta
    // quillInstance.getText();  // This is a string
    // quillInstance.root.innerHTML; // This is a html
    let content = isHtml
      ? quillInstance.getText()
      : quillInstance.root.innerHTML;
      
    if (!title || !image || !description || content === "\n" || content === "<p><br></p>") {
      showNotification({
        color: "red",
        title: "Blogs",
        message: "Please fill the fields",
      });
      return;
    }
    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", description);
    formData.append("content", content);
    formData.append("pdfContent", null);

    const resp = await apiWrapWithErrorWithData(
      blogId
        ? editBlog({ blogId, title, content })
        : createBlog({ data: formData })
    );
    if (resp.success) {
      showNotification({
        color: "green",
        title: "Blogs",
        message: `Blog ${blogId ? "updated" : "created"}`,
      });
      navigate("/app/blog-manager");
    } else {
      showNotification({
        color: "red",
        title: "Blogs",
        message: "Something went wrong.",
      });
    }
    setLoading(false);
  };

  const onDelete = async () => {
    const resp = await apiWrapWithErrorWithData(deleteBlog({ blogId }));
    if (resp?.success) {
      navigate("/app/blog-manager");
      showNotification({
        color: "green",
        title: "Blogs",
        message: `Blog is deleted`,
      });
    }
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const handleImageUpload = () => {
    const quillInstance = quillRef.current.getEditor();
    const input = fileInputRef.current;
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const range = quillInstance.getSelection();
        quillInstance.insertEmbed(range.index, "image", reader.result);
      };
      reader.readAsDataURL(file);
    };
  };

  return (
    <div style={{ width: "74%" }}>
      {blogId ? (
        <div className="flex justify-end">
          <Button
            style={{ backgroundColor: "red", color: "white" }}
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      ) : null}
      <TextInput
        value={title}
        label="Title"
        placeholder="Enter Title"
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextInput
        className="my-2"
        value={description}
        label="Description"
        placeholder="Enter Description"
        onChange={(e) => setDescription(e.target.value)}
      />
      <FileInput
        className="my-2"
        placeholder="Pick image"
        label="Thumbnail Image"
        onChange={setImage}
      />
      <Text>HTML Content</Text>
      <div id="toolbar">
        <select
          className="ql-header"
          defaultValue={"false"}
          onChange={(e) => e.persist()}
        >
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
          <option value="4">Heading 4</option>
          <option value="5">Heading 5</option>
          <option value="6">Heading 6</option>
          <option value="false">Normal</option>
        </select>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-align" value=""></button>
        <button className="ql-align" value="center"></button>
        <button className="ql-align" value="right"></button>
        <button className="ql-align" value="justify"></button>
        <button className="ql-image" onClick={handleImageUpload}></button>
        <button
          className="ql-code-block"
          onClick={() => setIsHtml((preState) => !preState)}
        ></button>
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <ReactQuill
        theme="snow"
        modules={modules}
        ref={quillRef}
        // onChange={handleQuillChange}
      />
      <div className="mt-5 flex justify-end">
        <div className="flex flex-row justify-between">
          {loading ? (
            <BeatLoader color={colors.primary} size={10} />
          ) : (
            <Button onClick={onChange}>
              {blogId ? "Edit Blog" : "Create Blog"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateBlogs;
